import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { GlobalService } from '../_services/global.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent implements OnInit {

  resetPasswordForm: FormGroup;
  
  @ViewChild('resetPasswordDirective', { static: false }) resetPasswordDirective: NgForm;

  constructor(private appService: AppService,
    public globalService: GlobalService) { }

  ngOnInit(): void {
    this.initResetPasswordForm();
  }

  initResetPasswordForm() {
    this.resetPasswordForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}")])
    });
  }

  passwordResetLink() {
    this.globalService.showLoader();
    this.appService.resetPassword(this.resetPasswordForm.value.email).subscribe((res) => {
      this.clearResetPasswordForm();
      this.globalService.hideLoader();
      this.globalService.addAlert('success', 'Reset password link sent to email. Please check the email address');
    }, (error) => {
      this.globalService.hideLoader();
      if (error.error.message == "")
        this.globalService.addAlert('danger', 'Something went wrong. Please try later');
      else
        this.globalService.addAlert('danger', error.error.message);

    })
  }

  clearResetPasswordForm() {
    this.resetPasswordForm.reset();
    this.resetPasswordDirective.resetForm();
  }

}
