import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "./storage.service";

@Injectable({
    providedIn: 'root',
})

export class AuthService {
    isLoggedIn = false;

    constructor(private storageService: StorageService, private router: Router) {
        const token = this.storageService.getItems('token');
        if (token == null) {
            this.isLoggedIn = false;
        } else {
            this.isLoggedIn = true;
        }
    }

    setLoginDetails(res) {
        this.storageService.setItems("token", res.token);
        this.storageService.setItems("userDetails", res.user);
        this.isLoggedIn = true;
    }

    clearLoginDetails() {
        this.storageService.clearAllItems();
        this.isLoggedIn = false;
        this.router.navigate(['/login']);
    }

}