export class Signup {
    public firstName: string;
    public lastName: string;
    public dob: string;
    public emailId: string;
    public phoneNo: number | string;
    public password: string;
    public aboutMe: string;
    public roleId: number;
    public address: Address[];
    public bank: Bank;
    public company: Company;
    public paymentGatewayId?:string;
    public optedIn:boolean;
    public acctToken?:string;

    constructor(firstName:string, lastName:string, dob:string,
        emailId:string, phoneNo:string, password:string, aboutMe:string,
        roleId:number,address:Address[], bank:Bank, company:Company,optedIn:boolean){
        this.firstName = firstName;
        this.lastName = lastName;
        this.dob = dob;
        this.emailId = emailId;
        this.phoneNo = phoneNo;
        this.password = password;
        this.aboutMe = aboutMe;
        this.roleId = roleId;
        this.address = address;
        this.bank = bank;
        this.company = company;
        this.optedIn = optedIn;
    }
}

export class Company {
    public name: string;
    public about: string;

    constructor(name:string, about:string){
        this.name = name;
        this.about = about;
    }
}

export class Bank {
    public accountNo: number | string;
    public irdNo: string;
    public taxCode: string;
    public cardNo: number | string;
    public cardName: string;
    public expiry: string;
    public securityCode: string;
    public planSelected: number;
    public yearly:boolean;
    public cvv: number;
    public cardToken:string;
    public companyCode:string;
    public paymentMethod:string;

    constructor(accountNo:number, irdNo:string, taxCode:string,
        cardNo:number,cardName:string, expiry:string, securityCode:string, selectedPlan:number, yearly:boolean, paymentMethod:string, companyCode:string){
        this.accountNo = accountNo;
        this.irdNo = irdNo;
        this.taxCode = taxCode;
        this.cardNo = cardNo;
        this.cardName = cardName;
        this.expiry = expiry;
        this.securityCode= securityCode;
        this.planSelected = selectedPlan;
        this.yearly = yearly;
        this.paymentMethod = paymentMethod;
        this.companyCode = companyCode;
    }
}

export class Address {
    public address2: string
    public postcode: string
    public city: string
    public address1: string
    public billingFirstName: string
    public billingLastName: string
    public addressTypeId: number
    constructor(address2:string, postcode:string, city:string,
        address1:string,billingFirstName:string, billingLastName:string, addressTypeId:number){
        this.address2 = address2;
        this.postcode = postcode;
        this.city = city;
        this.address1 = address1;
        this.billingFirstName = billingFirstName;
        this.billingLastName = billingLastName;
        this.addressTypeId= addressTypeId;
    }
}

export class Summary {
    appliedRate:number;
    totalJobs:number;
    totalApps:number;
    views:number;
    viewsCount:number;
    rating:number;
    constructor(appliedRate:number,totalJobs:number,totalApps:number,views:number,rating:number,viewsCount:number){
        this.appliedRate = appliedRate;
        this.totalApps = totalApps;
        this.totalJobs = totalJobs;
        this.views = views;
        this.viewsCount = viewsCount;
        this.rating = rating;
    }
}