import { DOCUMENT } from '@angular/common';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, ValidationErrors, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { AuthService } from '../_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StripeHelper } from '../_helpers/stripe.helper.service';
import { StorageService } from '../_services/storage.service';
import { GlobalService } from '../_services/global.service';
import { environment } from 'src/environments/environment';
import { Address, Bank, Company, Signup } from '../_models/signUp';
declare var $: any;

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  email = "support@temp.org.nz";
  sIndex: any;
  wIndex: any;

  yearFocus = false;
  dayFocus = false;
  monthFocus = false;
  expiryMonthFocus = false;
  isExpanded = false;
  comeFromBack = false;
  clientSecret = '';

  staff1form: FormGroup;
  staff2form: FormGroup;
  staff3form: FormGroup;
  staff4form: FormGroup;
  staff5form: FormGroup;
  staff6form: FormGroup;

  work1form: FormGroup;
  work2form: FormGroup;
  work3form: FormGroup;
  /* workFromBuilder: FormGroup;
  staffFromBuilder: FormGroup; */
  loginForm: FormGroup;
  resetPasswordForm: FormGroup;
  entryForm: FormGroup;
  staffJson: any;
  yearlist = [];
  monthlist = [];
  dayList = [];
  expiryYear = [];
  isWork = true;
  paymentMethod = 'card';
  companyCodeInvalid = false;
  showMonthYearToggle = false;

  activeTab: 'worker' | 'staff';

  isPasswordVisible = false;
  isConfirmPasswordVisible = false;

  message = null;

  myInterval = 3000;
  rate = 4;
  itemsPerSlide = 3;
  isReadonly = true;
  isArrowActive = false;
  isYearly: boolean = false;
  plansArr = [];
  cardToken = '';
  stripeCustId = '';
  stripeElement;
  isCheck = true;
  bankAccountToken = '';

  @ViewChild('signUp', { static: false }) signup: ElementRef;
  @ViewChild('signIn', { static: false }) signIn: ElementRef;

  @ViewChild('loginForm1', { static: false }) loginForm1: NgForm;
  @ViewChild('formDirective', { static: false }) formDirective: NgForm;
  @ViewChild('workDirective', { static: false }) workDirective: NgForm;
  @ViewChild('resetPasswordDirective', { static: false }) resetPasswordDirective: NgForm;
  showApp: boolean;
  size = 1;

  constructor(@Inject(DOCUMENT) private _document: Document, private appService: AppService,
    private router: Router, public authService: AuthService, private stripeHelper: StripeHelper,
    public globalService: GlobalService, private storageService: StorageService, private activatedRute: ActivatedRoute) {
    this.sIndex = 1;
    this.wIndex = 1;

  }

  ngOnInit(): void {
    this.initSignUpForm();
    if (this.router.url.includes('worker')) {
      this.activeTab = 'worker';
      this.isWork = true;
    } else {
      this.activeTab = 'staff';
      this.isWork = false;
    }

    /* const id = this.router.url.includes('worker') ? 'pills-Work-tab' : 'pills-Staff-tab';
    console.log(id);
        document.getElementById(id).click(); */
    /*  this.resetSignUpForm();*/
    //this.initResetPasswordForm();
    const year = new Date().getFullYear() - 15;
    const expYear = new Date().getFullYear();
    for (var i = 1; i < 76; i++) {
      this.yearlist.push(year - i);
    }
    for (var i = 0; i < 12; i++) {
      this.monthlist.push(i + 1);
    }
    for (var i = 1; i <= 31; i++) {
      this.dayList.push(i);
    }
    for (let i = 0; i <= 4; i++) {
      this.expiryYear.push(expYear + i)
    }
    if (document.cookie.indexOf("qjb_access_valid=true") == -1) {
      if (environment.API_URL.includes("ec2-13-211-141-249.ap-southeast-2.compute.amazonaws.com"))
        //$("#entryScreen").modal('show');
        this.showApp = (environment.API_URL.includes("ec2-13-211-141-249.ap-southeast-2.compute.amazonaws.com")) ? false : true;
    } else {
      this.showApp = true;
    }
    $("body").tooltip({ selector: '[data-toggle=tooltip]' });

    this.getPlansData();
  }

  ngAfterViewInit() {
    this.activatedRute.queryParams.subscribe(params => {
      let striperesponse = params['striperesponse'];
      this.router.navigate([]);
      if (striperesponse) {
        this.getUserDetails(striperesponse);
      }
    });
  }

  checkEmail(iswork = false) {
    this.globalService.showLoader();
    let email = iswork ? (this.work1form.get('email').value) : this.staff1form.get('email').value?.trim();
    this.appService.getEmailCount(email).subscribe(((res: any) => {
      this.globalService.hideLoader();
      if (res.count == 0) {
        if (iswork)
          this.wIndex = 2
        else
          this.sIndex = 2;
      } else {
        this.globalService.addAlert("danger", "Email Id already exists");
      }
    }))
  }

  createBankAccountToken() {
    this.globalService.showLoader();
    this.appService.createBankAccountToken(this.work2form.get('bankAccountNumber').value).subscribe(((res: any) => {
      this.globalService.hideLoader();
      this.bankAccountToken = res.bankAcctToken;
      this.wIndex = 3;
    }))
  }

  getUserDetails(striperesponse) {
    let userDetails = this.storageService.getItems("user");
    this.storageService.clearAllItems();
    if (userDetails)
      if (striperesponse == 0) {
        this.initSignUpForm(userDetails);
        this.stripeCustId = userDetails.paymentGatewayId;
        //$("#signup").modal('show');
        this.sIndex = 5;
      } else if (striperesponse == 1) {
        setTimeout(() => {
          this.isWork = false;
          this.signUp(userDetails);
        }, 2000);
      }
  }

  fieldsChange(event) {
    this.isCheck = event.currentTarget.checked;
  }

  getPlansData() {
    this.appService.getPlans().subscribe(((res: any) => {
      this.plansArr = res;
      for (let i = 0; i < this.plansArr.length; i++) {
        if (!this.plansArr[i].deleted && this.plansArr[i].monthlyPrice != 0 && this.plansArr[i].annualPrice != 0)
          this.showMonthYearToggle = true;
      }
    }))
  }

  ngAfterContentChecked(): void {
    //Called after every check of the component's or directive's content.
    //Add 'implements AfterContentChecked' to the class.
    $('.selectpicker').selectpicker();
  }

  initSignUpForm(staffDetails = null) {
    this.staff1form = new FormGroup({
      'firstName': new FormControl(staffDetails?.firstName ?? null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'lastName': new FormControl(staffDetails?.lastName ?? null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'phoneNumber': new FormControl(staffDetails?.phoneNo ?? null, [Validators.required, Validators.maxLength(12), Validators.pattern("^[0-9]*$"), this.noWhitespaceValidator]),
      'email': new FormControl(staffDetails?.emailId ?? null, [Validators.required, Validators.maxLength(30), Validators.minLength(6), Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}"), this.noWhitespaceValidator]),
      'address1': new FormControl(staffDetails?.address[1].address1 ?? null, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      'address2': new FormControl(staffDetails?.address[1].address2 ?? null, [Validators.maxLength(50)]),
      'city': new FormControl(staffDetails?.address[1].city ?? null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
      'postCode': new FormControl(staffDetails?.address[1].postcode ?? null, [Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")])
    });
    this.staff2form = new FormGroup({
      'password': new FormControl(staffDetails?.password ?? null, [Validators.required, Validators.minLength(8), Validators.maxLength(50), this.noWhitespaceValidator]),
      'confirmPassword': new FormControl(staffDetails?.password ?? null, [Validators.required, this.checkPasswordEqual.bind(this), Validators.minLength(8), Validators.maxLength(50), this.noWhitespaceValidator]),
      'companyName': new FormControl(staffDetails?.company.name ?? null, [Validators.required, Validators.minLength(2), Validators.maxLength(50), this.noWhitespaceValidator]),
      'aboutCompany': new FormControl(staffDetails?.company.about ?? null, [Validators.minLength(20), Validators.maxLength(500)]),
    });
    this.staff3form = new FormGroup({
      'billingFirstName': new FormControl(staffDetails?.address[0].billingFirstName ?? null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'billingLastName': new FormControl(staffDetails?.address[0].billingLastName ?? null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'city': new FormControl(staffDetails?.address[0].city ?? null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
      'billingAddress1': new FormControl(staffDetails?.address[0].address1 ?? null, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      'billingAddress2': new FormControl(staffDetails?.address[0].address2 ?? null, [Validators.maxLength(50)]),
      'postCode': new FormControl(staffDetails?.address[0].postcode ?? null, [Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")])
    });
    this.staff4form = new FormGroup({
      // 'cardName': new FormControl(staffDetails?.bank.cardName ?? null, [Validators.required, Validators.minLength(2), Validators.maxLength(15), this.noWhitespaceValidator]),
      // 'cardNumber': new FormControl(staffDetails?.bank.cardNo ?? null, [Validators.required, Validators.maxLength(16), Validators.pattern("^[0-9]*$"), this.noWhitespaceValidator]),
      // 'securityCode': new FormControl(null),
      // 'expiryMonth': new FormControl(staffDetails?.bank.expiry.split("/")[0] ?? null, [Validators.required]),
      // 'expiryYear': new FormControl(staffDetails?.bank.expiry.split("/")[1] ?? null, [Validators.required]),
      'planSelected': new FormControl(staffDetails?.bank.planSelected ?? 1),
      'isYearly': new FormControl(staffDetails?.bank.yearly ?? false),
      'paymentMethod': new FormControl(staffDetails?.bank.paymentMethod ?? 'card'),
      'companyCode': new FormControl(staffDetails?.bank.companyCode ?? 'card'),
      // 'cvv': new FormControl(staffDetails?.bank.cvv ?? null,[Validators.required, Validators.minLength(3), Validators.maxLength(4)])
    }),
      this.staff5form = new FormGroup({
        'agreed': new FormControl(false, Validators.requiredTrue),
        'optedins': new FormControl(true)
      });
    this.staff6form = new FormGroup({
    });
    this.staff4form.patchValue({ planSelected: 1, paymentMethod: 'card' })
    this.work1form = new FormGroup({
      'firstName': new FormControl(null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'lastName': new FormControl(null, [Validators.required, Validators.maxLength(20), this.noWhitespaceValidator]),
      'day': new FormControl(null, [Validators.required]),
      'month': new FormControl(null, [Validators.required]),
      'year': new FormControl(null, [Validators.required]),
      'phoneNumber': new FormControl(null, [Validators.required, Validators.maxLength(12), Validators.pattern("^[0-9]*$"), this.noWhitespaceValidator]),
      'email': new FormControl(null, [Validators.required, Validators.maxLength(30), Validators.minLength(6), Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}"), this.noWhitespaceValidator]),
      'address1': new FormControl(null, [Validators.required, Validators.maxLength(50), this.noWhitespaceValidator]),
      'address2': new FormControl(null, [Validators.maxLength(50)]),
      'city': new FormControl(null, [Validators.required, Validators.maxLength(30), this.noWhitespaceValidator]),
      'postCode': new FormControl(null, [Validators.minLength(4), Validators.maxLength(4), Validators.pattern("^[0-9]*$")])
    });
    this.work2form = new FormGroup({
      /*  'irdNumber': new FormControl(null, [Validators.required, Validators.maxLength(12), Validators.pattern("^[0-9]*$"), this.noWhitespaceValidator]),
       'taxCode': new FormControl(null, [Validators.required, Validators.maxLength(5), this.noWhitespaceValidator]), */
      'bankAccountNumber': new FormControl(null, [Validators.required, Validators.minLength(15), Validators.maxLength(16), Validators.pattern("^[0-9]*$"), this.noWhitespaceValidator]),
      'password': new FormControl(null, [Validators.required, Validators.minLength(8), Validators.maxLength(50), this.noWhitespaceValidator]),
      'confirmPassword': new FormControl(null, [Validators.required, this.checkPassword.bind(this), Validators.minLength(8), Validators.maxLength(50), this.noWhitespaceValidator]),
      'aboutYou': new FormControl(null, [Validators.minLength(20), Validators.maxLength(500)])
    });
    this.work3form = new FormGroup({
      'agreed': new FormControl(false, Validators.requiredTrue),
      'optedin': new FormControl(true)
    });
    this.isWork = true;
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }

  checkPasswordEqual(control: FormControl): ValidationErrors | null {
    if (typeof this.staff2form != "undefined")
      return control.value === this.staff2form.get("password").value ? null : {
        NotEqual: true
      };
    return null;
  }

  checkPassword(control: FormControl): ValidationErrors | null {
    if (typeof this.work2form != "undefined")
      return control.value === this.work2form.get("password").value ? null : {
        NotEqual: true
      };
  }

  public noWhitespaceValidator(control: FormControl) {
    if (control && control.value && !control.value.replace(/\s/g, '').length) {
      let removedSpaces = control.value.split(' ').join('');
      control.setValue(removedSpaces);
      return { required: true }
    }
    return null;
  }

  submitCardDetails() {
    let bank = new Bank(null, null, null, this.staff4form.get('cardNumber').value?.trim(),
      this.staff4form.get('cardName').value?.trim(),
      this.staff4form.get('expiryMonth').value + '/' +
      this.staff4form.get('expiryYear').value,
      this.staff4form.get('securityCode').value?.trim(),
      this.staff4form.get('planSelected').value,
      this.staff4form.get('isYearly').value,
      this.staff4form.get('paymentMethod').value,
      this.staff4form.get('companyCode').value);
    bank.cvv = this.staff4form.get('cvv').value;
    this.globalService.showLoader();
    this.appService.saveCardToken(bank).subscribe((res: any) => {
      this.cardToken = res.token;
      this.globalService.hideLoader();
      this.sIndex = 5;
    })
  }

  signUpStaff() {
    const company = new Company(this.staff2form.get('companyName').value,
      this.staff2form.get('aboutCompany').value?.trim());
    const bank = new Bank(null, null, null, null,
      null,
      null,
      null,
      this.staff4form.get('planSelected').value,
      this.staff4form.get('isYearly').value,
      this.staff4form.get('paymentMethod').value,
      this.staff4form.get('companyCode').value);
    bank.cardToken = this.cardToken;
    const billingAdd = new Address(
      this.staff3form.get('billingAddress2').value?.trim(),
      this.staff3form.get('postCode').value?.trim(),
      this.staff3form.get('city').value?.trim(),
      this.staff3form.get('billingAddress1').value?.trim(),
      this.staff3form.get('billingFirstName').value?.trim(),
      this.staff3form.get('billingLastName').value?.trim(),
      1
    );
    const resAddress = new Address(
      this.staff1form.get('address2')?.value?.trim(),
      this.staff1form.get('postCode')?.value?.trim(),
      this.staff1form.get('city').value?.trim(),
      this.staff1form.get('address1').value?.trim(),
      null, null,
      2
    );
    const address = [billingAdd, resAddress]
    const payload = new Signup(this.staff1form.get('firstName').value?.trim(),
      this.staff1form.get('lastName').value?.trim(),
      null, this.staff1form.get('email').value?.trim(),
      this.staff1form.get('phoneNumber').value?.trim(),
      this.staff2form.get('password').value?.trim(),
      null, 2, address, bank, company, this.isCheck);
    payload.paymentGatewayId = this.stripeCustId;
    return payload;
    // this.collectPayment(payload);
    // this.collectPaymentForCard(payload);
  }

  checkCompanyUniqueness(payload) {
    this.globalService.showLoader();
    this.appService.getCompanyUniqueness(payload.bank.companyCode).subscribe(((res: any) => {
      this.globalService.hideLoader();

      if (!res)
        this.globalService.addAlert("danger", "Company code is invalid.");
      else
        this.sIndex = 5;
    }))
  }

  async paymentSuccessLink() {
    let elements = this.stripeElement;
    let payload = this.signUpStaff();
    this.storageService.setItems("user", payload);
    if (payload.bank.paymentMethod == 'invoice') {
      window.location.href = window.location.href + "?striperesponse=1";
    }
    let plan = this.plansArr.find((res) => res.id === parseInt(payload.bank.planSelected.toString()));
    if (plan.monthlyPrice != 0) {
      const { error } = await this.stripeHelper.stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              address: {
                country: "NZ",
                postal_code: "0600"
              }
            }
          },
          return_url: window.location.href + "?striperesponse=1",
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
        this.globalService.addAlert("danger", error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    } else {
      const { error } = await this.stripeHelper.stripe.confirmSetup({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          payment_method_data: { billing_details: { address: { country: "NZ", postal_code: "0600" } } },
          return_url: window.location.href + "?striperesponse=1",
        }
      });

      if (error) {
        // This point will only be reached if there is an immediate error when
        // confirming the payment. Show error to your customer (for example, payment
        // details incomplete)
        const messageContainer = document.querySelector('#error-message');
        messageContainer.textContent = error.message;
        this.globalService.addAlert("danger", error.message);
      } else {
        // Your customer will be redirected to your `return_url`. For some payment
        // methods like iDEAL, your customer will be redirected to an intermediate
        // site first to authorize the payment, then redirected to the `return_url`.
      }
    }
  }

  collectPaymentForCard(payload) {
    // let obj = this.plansArr.find((res)=> res.id === parseInt(payload.bank.planSelected));
    // if(obj.monthlyPrice !==  0){
    this.globalService.showLoader();
    this.appService.getSubscriptionForCard(payload).subscribe((res: any) => {
      this.globalService.hideLoader();
      // this.sIndex=6;
      this.stripeCustId = res.stripeUserId;
      this.clientSecret = res.clientSecret;
      this.stripeElement = this.stripeHelper.getParentElement(res.clientSecret, '#payment-element');
    })
    // }else
    //   this.signUp(payload);
  }

  cardDetailsEntered() {
    let payload = this.signUpStaff();
    if (payload.bank.paymentMethod == 'invoice') {
      this.checkCompanyUniqueness(payload);
    } else {
      let valid = true;
      const inputElements = document.querySelector('#payment-element').getElementsByTagName('input');
      for (let i = 0; i < inputElements.length; i++) {
        const element = inputElements[i];
        if (element.classList.contains("Input--empty") || element.classList.contains("Input--invalid")) {
          valid = false;
          break;
        }
      }
      if (valid)
        this.sIndex = 5;
    }
  }

  getPaymentElement(planId) {
    this.staff4form.patchValue({ planSelected: planId, paymentMethod: 'card' })
    let payload = this.signUpStaff();
    let plan = this.plansArr.find((res) => res.id === parseInt(payload.bank.planSelected.toString()));
    if (plan.monthlyPrice != 0)
      this.collectPaymentForCard(payload);
    else
      this.casualSelected(payload);
  }

  getCasualElement() {
    let payload = this.signUpStaff();
    this.casualSelected(payload);
    this.sIndex = 4;
    return true;
  }

  casualSelected(payload) {
    this.globalService.showLoader();
    this.appService.getSetupIntentForCard(payload).subscribe((res: any) => {
      this.globalService.hideLoader();
      // this.sIndex=6;
      this.stripeCustId = res.stripeUserId;
      this.clientSecret = res.clientSecret;
      this.stripeElement = this.stripeHelper.getParentElement(res.clientSecret, '#payment-element');
    })
  }

  collectPayment(payload) {
    let obj = this.plansArr.find((res) => res.id === parseInt(payload.bank.planSelected));
    if (obj.monthlyPrice !== 0) {
      this.globalService.showLoader();
      this.appService.getSubscription(payload).subscribe((res: any) => {
        this.globalService.hideLoader();
        payload.paymentGatewayId = res.paymentGatewayId;
        this.storageService.setItems("user", payload);
        window.location.href = res.redirectURL;
      })
    } else
      this.signUp(payload);
  }

  signUpWork() {
    const bank = new Bank(this.work2form.get('bankAccountNumber').value?.trim(),
      null,
      null,
      null, null, null, null, null, false, null, null);
    const resAddress = new Address(
      this.work1form.get('address2').value?.trim(),
      this.work1form.get('postCode').value?.trim(),
      this.work1form.get('city').value?.trim(),
      this.work1form.get('address1').value?.trim(),
      null, null, 2
    );
    const address = [resAddress]
    const payload = new Signup(this.work1form.get('firstName').value?.trim(),
      this.work1form.get('lastName').value?.trim(),
      this.work1form.get('day').value + '/' +
      this.work1form.get('month').value + '/' +
      this.work1form.get('year').value,
      this.work1form.get('email').value?.trim(),
      this.work1form.get('phoneNumber').value?.trim(),
      this.work2form.get('password').value?.trim(),
      this.work2form.get('aboutYou').value?.trim(), 1,
      address, bank, null, this.isCheck);
    payload.acctToken = this.bankAccountToken;
    this.signUp(payload);
  }

  signUp(payload) {
    this.globalService.showLoader();
    this.appService.signUp(payload).subscribe((res: any) => {
      this.authService.setLoginDetails(res);
      this.resetSignUpForm();
      this.signup.nativeElement.click();
      this.globalService.hideLoader();
      this.globalService.addAlert('success', 'Account created successfully');
      this.isWork ? this.router.navigate([`./worker`]) : this.router.navigate([`./business`]);
    }, (error) => {
      this.globalService.hideLoader();
      if (error.status === 409)
        this.globalService.addAlert('danger', error.error.message);
      else
        this.globalService.addAlert('danger', 'Something went wrong. Please try later');
    });
  }

  resetSignUpForm() {
    $(".selectpicker").selectpicker("refresh");
    this.staff1form.reset();
    this.staff2form.reset();
    this.staff3form.reset();
    this.staff4form.reset();
    this.staff5form.reset();
    this.isYearly = false;
    this.staff4form.patchValue({ planSelected: 1, paymentMethod: 'card' })
    this.staff5form.patchValue({ optedins: true })
    this.work1form.reset();
    this.work2form.reset();
    this.work3form.reset();
    this.work3form.patchValue({ optedin: true })
    this.sIndex = 1;
    this.wIndex = 1;
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }


  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  toggleConfirmPassword() {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  }

  onOptionsSelected() {
    if (this.work1form.get('year').value && this.work1form.get('month').value) {
      this.dayList = [];
      const totalDays = new Date(this.work1form.get('year').value, this.work1form.get('month').value, 0).getDate();
      this.work1form.get('day').reset();
      for (var i = 1; i <= totalDays; i++) {
        this.dayList.push(i);
      }
      setTimeout(() => {
        $(".day").val('default').selectpicker("refresh");
      }, 100);
    }
  }

  checkValidUser() {
    this.appService.showApplication(this.entryForm.value).subscribe((res: any) => {
      if (res.valid) {
        document.cookie = "qjb_access_valid=true";
        $("#entryScreen").modal('hide');
        this.showApp = true;
      } else {
        this.showApp = false;
        this.globalService.addAlert('danger', 'Invalid username or password');
      }
    });
  }

  termsAndConditionsBack() {
    this.sIndex = 4;
    this.navigateStaffManually();
  }

  paymentsBack() {
    this.sIndex = 5;
    this.navigateStaffManually();
  }

  navigateStaffManually() {
    this.comeFromBack = true;
    setTimeout(() => {
      document.getElementById("pills-Staff-tab").click();
      this.comeFromBack = false;
    }, 100);
  }

  toggleChange(event) {
    this.isYearly = event.target.checked;
    if (this.staff4form.get("planSelected").value != 1)
      this.getPaymentElement(1);
  }

  workClicked() {
    console.log("coming here")
    if (!this.comeFromBack) {
      console.log("coming inside if")
      this.formDirective?.resetForm();
      this.isWork = false;
      //this.resetSignUpForm();
      this.navigateSignup('staff');
    }
  }

  refreshPage() {
    this._document.defaultView.location.reload();
  }


  clickToPrice() {
    if (!document.getElementById("planandprice")) {
      document.getElementById('forBusiness').click();
      setTimeout(() => {
        document.getElementById('planandprice').scrollIntoView({ behavior: 'smooth' });
      }, 1000);
    } else {
      document.getElementById('planandprice').scrollIntoView({ behavior: 'smooth' });
    }
  }

  paymentMethodChanged(event) {
    this.paymentMethod = event.target.value;
    if (event.target.value == 'card') {
      let payload = this.signUpStaff();
      this.casualSelected(payload);
    }
  }

  navigateSignup(key: string) {
    console.log("called");
    if (key == 'worker') {
      this.router.navigate(['./worker-signup']);
    } else {
      this.router.navigate(['./business-signup']);
    }

  }

  navigateLogin() {
    this.router.navigate(['./login']);
  }

}
