<!-- <div class=" my-7 container w-90">
    <div class="brand-logo">
        <img src="../assets/image/png/Logo.png" height="40px" alt="Logo" class="h-px-sm-34">

    </div>
</div>
<div class="container-fluid vh-100">
    <div class="d-flex h-100 justify-content-center align-items-center ">
        <div class="col-12 col-sm-8 col-md-6 col-lg-4">
            <div class="card">
                <div class="card-body">
                    <h4 class="card-title text-left my-8">Login</h4>
                    <form [formGroup]="loginForm" #loginForm1="ngForm" (ngSubmit)="loginForm.valid && login()">
                        <small class="text-danger mb-2 d-flex position-relative" *ngIf="message">
                            {{message}}
                        </small>
                        <div class="form-group email-group mb-6 mb-md-7">
                            <div class="position-relative">
                                <i class="position-absolute fas fa-user-circle beginIcon"></i>
                                <input type="text"
                                    class="form-control pr-9 pl-10 border-none bg-gallery-opacity-visible"
                                    placeholder="Email" formControlName="username">
                                <ng-container *ngIf="(loginForm1.submitted)  && loginForm.get('username').invalid">
                                    <small class="text-danger" *ngIf="loginForm.get('username').errors.required">
                                        Field is required
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="!loginForm.get('username').errors.required && loginForm.get('username').errors.pattern.requiredPattern">
                                        Invaild email address
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group password-group mb-6 mb-md-7">
                            <div class="position-relative">
                                <i class="position-absolute password-eye fa" (click)="togglePassword()"
                                    [ngClass]="{'fa-eye-slash ': !isPasswordVisible, 'fa-eye':isPasswordVisible}"></i>
                                <i class="position-absolute fas fa-lock beginIcon"></i>
                                <input [type]="isPasswordVisible?'text':'password'" autocomplete="on"
                                    class="form-control pr-9 pl-10 border-none bg-gallery-opacity-visible"
                                    formControlName="password" placeholder="Password">
                                <ng-container *ngIf="(loginForm1.submitted)  && loginForm.get('password').invalid">
                                    <small class="text-danger" *ngIf="loginForm.get('password').errors.required">
                                        Field is required
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group mb-6 mb-md-7 d-flex flex-wrap justify-content-between">
                            <label for="terms-check"
                                class="customcheckbox-container mb-md-7 mb-0 font-size-3 pl-7 line-height-reset gr-check-input d-flex">Remember
                                me
                                <input type="checkbox" id="terms-check">
                                <span class="checkmark"></span>
                            </label>
                            <span (click)="openResetPasswordForm()" role="button"
                                class="font-size-3 text-dodger line-height-reset">Forgot
                                Password</span>
                        </div>
                        <div class="form-group mb-md-8 mb-3">
                            <button type="submit" class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                                style="height:48px !important">Log in
                            </button>
                        </div>
                        <p class="font-size-4 text-center heading-default-color">Don’t have an account?<span
                                role="button" (click)="navigateSignUp()" class="text-primary">&nbsp;Sign up</span></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->


<div class="page-container">
    <header class="py-7">
        <div class="container">
            <img src="../assets/image/png/Logo.png" height="40" alt="Logo">
        </div>
    </header>

    <main class="login-container">
        <div class="login-card">
            <div class="card">
                <div class="card-body">
                    <h3 class="card-title mb-10 mt-4">Login</h3>
                    <form [formGroup]="loginForm" #loginForm1="ngForm" (ngSubmit)="loginForm.valid && login()">
                        <small class="text-danger mb-2 d-flex position-relative" *ngIf="message">
                            {{message}}
                        </small>
                        <div class="form-group email-group mb-6 mb-md-7">
                            <div class="position-relative">
                                <i class="position-absolute fas fa-user-circle beginIcon"></i>
                                <input type="text"
                                    class="form-control pr-9 pl-10 border-none bg-gallery-opacity-visible"
                                    placeholder="Email" formControlName="username">
                                <ng-container *ngIf="(loginForm1.submitted)  && loginForm.get('username').invalid">
                                    <small class="text-danger" *ngIf="loginForm.get('username').errors.required">
                                        Field is required
                                    </small>
                                    <small class="text-danger"
                                        *ngIf="!loginForm.get('username').errors.required && loginForm.get('username').errors.pattern.requiredPattern">
                                        Invaild email address
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group password-group mb-6 mb-md-7">
                            <div class="position-relative">
                                <i class="position-absolute password-eye fa" (click)="togglePassword()"
                                    [ngClass]="{'fa-eye-slash ': !isPasswordVisible, 'fa-eye':isPasswordVisible}"></i>
                                <i class="position-absolute fas fa-lock beginIcon"></i>
                                <input [type]="isPasswordVisible?'text':'password'" autocomplete="on"
                                    class="form-control pr-9 pl-10 border-none bg-gallery-opacity-visible"
                                    formControlName="password" placeholder="Password">
                                <ng-container *ngIf="(loginForm1.submitted)  && loginForm.get('password').invalid">
                                    <small class="text-danger" *ngIf="loginForm.get('password').errors.required">
                                        Field is required
                                    </small>
                                </ng-container>
                            </div>
                        </div>
                        <div class="form-group mb-6 mb-md-7 d-flex flex-wrap justify-content-between">
                            <label for="terms-check"
                                class="customcheckbox-container mb-md-7 mb-0 font-size-3 pl-7 line-height-reset gr-check-input d-flex">Remember
                                me
                                <input type="checkbox" id="terms-check">
                                <span class="checkmark"></span>
                            </label>
                            <span (click)="openResetPasswordForm()" role="button"
                                class="font-size-3 text-dodger line-height-reset">Forgot
                                Password</span>
                        </div>
                        <div class="form-group mb-md-8 mb-3">
                            <button type="submit" class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase"
                                style="height:48px !important">Log in
                            </button>
                        </div>
                        <p class="font-size-4 text-center heading-default-color">Don’t have an account?<span
                                role="button" (click)="navigateSignUp()" class="text-primary">&nbsp;Sign up</span></p>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>