import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { AppService } from '../app.service';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { GlobalService } from '../_services/global.service';
import { StorageService } from '../_services/storage.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  message = null;

  isPasswordVisible = false;
  isConfirmPasswordVisible = false;

  resetPasswordForm: FormGroup;


  @ViewChild('loginForm1', { static: false }) loginForm1: NgForm;

  constructor(private appService: AppService,
    private router: Router, public authService: AuthService,public globalService: GlobalService,private storageService: StorageService,) { 
      this.initLoginForm();
    }

  ngOnInit(): void {
    this.initLoginForm();
  }

  initLoginForm() {
    if ($('body').find('.hamburgerBackdrop').length) {
      $('body').find('.navbar-toggler').click();
    }
    this.loginForm = new FormGroup({
      'username': new FormControl('', [Validators.required, Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}")]),
      'password': new FormControl('', Validators.required)
    });
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }

  login() {
    this.globalService.showLoader();
    this.appService.login(this.loginForm.value).subscribe((res: any) => {
      this.authService.setLoginDetails(res);
      this.message = null;
      this.resetLoginForm();
      //this.signIn.nativeElement.click();
      this.globalService.hideLoader();
      (res.user.roleId == 1) ? this.router.navigate([`./worker`]) : this.router.navigate(['./business']);
    }, (error) => {
      this.globalService.hideLoader();
      this.message = "Invalid Username or Password";
    });
  }

  togglePassword() {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  resetLoginForm() {
    this.message = null;
    this.loginForm.reset();
    this.loginForm1.resetForm();
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
  }

  openResetPasswordForm() {
    this.resetLoginForm();
    this.router.navigate(['./forgot-password']);
  }

  initResetPasswordForm() {
    this.resetPasswordForm = new FormGroup({
      'email': new FormControl(null, [Validators.required, Validators.pattern("[a-zA-Z0-9.\\-_]{1,}@[a-zA-Z0-9.\\-]{2,}[.]{1}[a-zA-Z]{2,}")])
    });
  }

  navigateSignUp() {
    this.resetLoginForm();
    this.router.navigate(['./worker-signup']);
  }

  resetSignUpForm() {
   /*  $(".selectpicker").selectpicker("refresh");
    this.staff1form.reset();
    this.staff2form.reset();
    this.staff3form.reset();
    this.staff4form.reset();
    this.staff5form.reset();
    this.isYearly = false;
    this.staff4form.patchValue({planSelected:1, paymentMethod:'card'})
    this.staff5form.patchValue({optedins:true})
    this.work1form.reset();
    this.work2form.reset();
    this.work3form.reset();
    this.work3form.patchValue({optedin:true})
    this.sIndex = 1;
    this.wIndex = 1;
    this.isPasswordVisible = false;
    this.isConfirmPasswordVisible = false;
 */  }

}
