<div class="page-container">
    <header class="py-7">
        <div class="container">
            <img src="../assets/image/png/Logo.png" height="40" alt="Logo">
        </div>
    </header>
    <main class="login-container">
        <div class="login-card">
            <div class="card">
                <div class="card-body">
                    <h3 class="text-center font-size-10">Forgot Password</h3>
                    <span class="d-flex justify-content-center text-muted mt-3 font-size-4">Please enter your email
                        to reset your password</span>
                    <form [formGroup]="resetPasswordForm" #resetPasswordDirective="ngForm"
                        (ngSubmit)="resetPasswordForm.valid && passwordResetLink()">
                        <div class="form-group position-relative mt-13">
                            <i class="position-absolute fas fa-user-circle beginIcon"></i>
                            <input type="text" class="form-control pr-9 pl-10 border-none bg-gallery-opacity-visible"
                                placeholder="Email" formControlName="email">
                            <ng-container
                                *ngIf="(resetPasswordDirective.submitted)  && resetPasswordForm.get('email').invalid">
                                <small class="text-danger" *ngIf="resetPasswordForm.get('email').errors.required">
                                    Field is required
                                </small>
                                <small class="text-danger"
                                    *ngIf="!resetPasswordForm.get('email').errors.required && resetPasswordForm.get('email').errors.pattern">
                                    Invaild email address
                                </small>
                            </ng-container>
                        </div>
                        <div class="form-group mb-8">
                            <button type="submit" class="btn btn-primary btn-medium w-100 rounded-5 text-uppercase">SEND
                                PASSWORD
                                RESET LINK
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </main>
</div>