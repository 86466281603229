<div class="page-container">
    <header class="py-7">
        <div class="container">
            <img src="../assets/image/png/Logo.png" height="40" alt="Logo">
        </div>
    </header>

    <main class="login-container">
        <div class="login-card mb-10">
            <div class="col-12 col-4 pb-0">
                <div class="col-12 right-column overflow-y-auto p-0 px-10 pb-5">
                    <div class="bg-white-2 h-100 pt-md-10 pt-7">
                        <div class="container text-center pb-4">
                            <span class="display-5">I'm Looking For...</span>
                        </div>
                        <ul class="signUpModal nav nav-pills mb-3 d-flex w-100 justify-content-center"
                            id="pills-tab" role="tablist">
                            <li class="nav-item w-50" id="workClick" (click)="navigateSignup('worker')">
                                <a class="nav-link colorUnset text-center active signHeight" id="pills-Work-tab"
                                    [class.active]="activeTab === 'worker'" data-toggle="tab" href="#pillswork"
                                    role="tab" aria-controls="pillswork"
                                    aria-selected="true"><span>Jobs</span></a>
                            </li>
                            <li class="nav-item w-50" id="staffClick" (click)="navigateSignup('staff')">
                                <a class="nav-link colorUnset text-center signHeight" id="pills-Staff-tab"
                                    [class.active]="activeTab === 'staff'" data-toggle="tab" href="#pillsstaff"
                                    role="tab" aria-controls="pillsstaff"
                                    aria-selected="true"><span>Workers</span></a>
                            </li>
                        </ul>
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade" [class.show]="activeTab === 'worker'"
                                [class.active]="activeTab === 'worker'" id="pillswork" role="tabpanel"
                                aria-labelledby="pillswork">
                                <div class="stepper md-2">
                                    <ul class="nav nav-tabs border-0 d-flex justify-content-center"
                                        role="tablist" style="top: 15px;">
                                        <li style="margin-left: 10%;"
                                            [ngClass]="{'active': (wIndex == 1), 'completed': (wIndex > 1) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-1"
                                                role="tab" title="Step 1">
                                                <span class="round-tab">1</span>
                                            </a>
                                        </li>
                                        <li
                                            [ngClass]="{'active': (wIndex == 2), 'disabled': (wIndex < 2), 'completed': (wIndex > 2) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-2"
                                                role="tab" title="Step 2">
                                                <span class="round-tab">2</span>
                                            </a>
                                        </li>
                                        <li [ngClass]="{'active': (wIndex == 3), 'disabled': (wIndex != 3) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-3"
                                                role="tab" title="Step 3">
                                                <span class="round-tab">3</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row no-gutters margin-top-3">
                                    <div class="work-step-1 col-12" *ngIf="wIndex  == 1">
                                        <form [formGroup]="work1form" #workDirective="ngForm"
                                            (ngSubmit)="!work1form.invalid && checkEmail(true)">
                                            <div class="row no-gutters">
                                                <div class="form-group col-6 pr-1">
                                                    <input type="text" class="form-control"
                                                        placeholder="First Name" formControlName="firstName">
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('firstName').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work1form.get('firstName').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('firstName').errors.required && work1form.get('firstName').errors.maxlength ">
                                                            Maximum 20 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div class="form-group col-6 pl-1">
                                                    <input type="text" class="form-control"
                                                        placeholder="Last Name" formControlName="lastName">
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('lastName').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work1form.get('lastName').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('lastName').errors.required && work1form.get('lastName').errors.maxlength ">
                                                            Maximum 20 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="row no-gutters">
                                                <div class="form-group col-3">
                                                    <small
                                                        class="DOB d-flex align-items-center justify-content-center">Birthday</small>
                                                </div>
                                                <div class="form-group col-3 DOB border-radius-0 border-left-0">
                                                    <select data-size="7"
                                                        class="selectpicker form-control border-radius-0 px-2"
                                                        formControlName="year" (change)='onOptionsSelected()'
                                                        title="Year">
                                                        <option [ngValue]="year" *ngFor="let year of yearlist">
                                                            {{year}}
                                                        </option>
                                                    </select>
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('year').invalid">
                                                        <small
                                                            class="text-danger pl-md-1 pl-0 word-wrap errorMsg"
                                                            *ngIf="work1form.get('year').errors.required">
                                                            Field is required
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div
                                                    class="form-group col-3 DOB border-radius-0 month-group border-left-0">
                                                    <select data-size="7"
                                                        class="selectpicker form-control border-radius-0 px-2"
                                                        formControlName="month" (change)="onOptionsSelected()"
                                                        title="Month">
                                                        <option [ngValue]="month"
                                                            *ngFor="let month of monthlist">{{month}}
                                                        </option>
                                                    </select>
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('month').invalid">
                                                        <small
                                                            class="text-danger pl-md-1 pl-0 word-wrap errorMsg"
                                                            *ngIf="work1form.get('month').errors.required">
                                                            Field is required
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div
                                                    class="form-group col-3 DOB border-radius-left-0 border-left-0">
                                                    <select data-size="7"
                                                        class="selectpicker day form-control position-absolute rounded-right-px-5 rounded-left-0 px-2 z-index-1"
                                                        formControlName="day" title="Day">
                                                        <option [ngValue]="day" *ngFor="let day of dayList">
                                                            {{day}}</option>
                                                    </select>
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('day').invalid">
                                                        <small
                                                            class="text-danger pl-md-1 pl-0 word-wrap errorMsg"
                                                            *ngIf="work1form.get('day').errors.required">
                                                            Field is required
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Phone Number" formControlName="phoneNumber">
                                                <ng-container
                                                    *ngIf="(workDirective.submitted)  && work1form.get('phoneNumber').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="work1form.get('phoneNumber').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger" *ngIf="!work1form.get('phoneNumber').errors.required && !work1form.get('phoneNumber').errors.pattern &&
                                           work1form.get('phoneNumber').errors.maxlength ">
                                                        Maximum 12 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work1form.get('phoneNumber').errors.required && work1form.get('phoneNumber').errors.pattern">
                                                        Invalid phone number
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fas fa-info-circle"
                                                        tooltip="john@email.com" placement="left"></i>
                                                    <input type="text" class="form-control" placeholder="Email"
                                                        formControlName="email">
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('email').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work1form.get('email').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('email').errors.required && work1form.get('email').errors.pattern">
                                                            Invaild email address
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('email').errors.required && !work1form.get('email').errors.pattern && work1form.get('email').errors.maxlength">
                                                            Maximum 30 Characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('email').errors.required && !work1form.get('email').errors.pattern && work1form.get('email').errors.minlength">
                                                            Minimum 6 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 1" formControlName="address1">
                                                <ng-container
                                                    *ngIf="(workDirective.submitted)  && work1form.get('address1').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="work1form.get('address1').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work1form.get('address1').errors.required && work1form.get('address1').errors.maxlength ">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 2" formControlName="address2">
                                                <ng-container
                                                    *ngIf="(workDirective.submitted)  && work1form.get('address2').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="work1form.get('address2').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work1form.get('address2').errors.required && work1form.get('address2').errors.maxlength ">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="row col-12 no-gutters px-0">
                                                <div class="form-group col-6 pr-1">
                                                    <input type="text" class="form-control" placeholder="City"
                                                        name="city" formControlName="city">
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('city').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work1form.get('city').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('city').errors.required && work1form.get('city').errors.maxlength ">
                                                            Maximum 30 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div class="form-group post-code-group col-6 pl-1">
                                                    <input type="text" class="form-control"
                                                        placeholder="Post Code" formControlName="postCode"
                                                        required>
                                                    <ng-container
                                                        *ngIf="(workDirective.submitted)  && work1form.get('postCode').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work1form.get('postCode').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger pl-1"
                                                            *ngIf="!work1form.get('postCode').errors.required && !work1form.get('postCode').errors.pattern && (work1form.get('postCode').errors.maxlength || work1form.get('postCode').errors.minlength) ">
                                                            Invalid post code
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work1form.get('postCode').errors.required && work1form.get('postCode').errors.pattern ">
                                                            Invalid post code
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group mb-5 mb-0 mt-md-7">
                                                <button type="submit"
                                                    class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                            </div>
                                            <p class="font-size-4 text-center heading-default-color" (click)="navigateLogin()">Already have an account?<span
                                                role="button" class="text-primary">&nbsp;Sign in</span></p>
                                        </form>
                                        
                                    </div>
                                    <div class="work-step-2 col-12" *ngIf="wIndex  == 2">
                                        <form [formGroup]="work2form" #workDirective1="ngForm"
                                            (ngSubmit)="!work2form.invalid && createBankAccountToken()">
                                            <!-- <div class="row no-gutters">
                                      <div class="form-group col-8 pr-1">
                                        <input type="text" class="form-control" placeholder="IRD Number"
                                          formControlName="irdNumber">
                                        <ng-container *ngIf="(workDirective1.submitted)  && work2form.get('irdNumber').invalid">
                                          <small class="text-danger" *ngIf="work2form.get('irdNumber').errors.required">
                                            Field is required
                                          </small>
                                          <small class="text-danger"
                                            *ngIf="!work2form.get('irdNumber').errors.required && !work2form.get('irdNumber').errors.pattern && work2form.get('irdNumber').errors.maxlength ">
                                            Maximum 12 Characters
                                          </small>
                                          <small class="text-danger"
                                            *ngIf="!work2form.get('irdNumber').errors.required && work2form.get('irdNumber').errors.pattern ">
                                            Invalid IRD number
                                          </small>
                                        </ng-container>
                                      </div>
                                      <div class="form-group col-4 pl-1">
                                        <input type="text" class="form-control" placeholder="Tax Code" formControlName="taxCode">
                                        <ng-container *ngIf="(workDirective1.submitted)  && work2form.get('taxCode').invalid">
                                          <small class="text-danger" *ngIf="work2form.get('taxCode').errors.required">
                                            Field is required
                                          </small>
                                          <small class="text-danger"
                                            *ngIf="!work2form.get('taxCode').errors.required && work2form.get('taxCode').errors.minlength ">
                                            Minimum 2 Characters
                                          </small>
                                          <small class="text-danger"
                                            *ngIf="!work2form.get('taxCode').errors.required && work2form.get('taxCode').errors.maxlength ">
                                            Maximum 5 Characters
                                          </small>
                                        </ng-container>
                                      </div>
                                    </div> -->
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Bank account Number"
                                                    formControlName="bankAccountNumber">
                                                <ng-container
                                                    *ngIf="(workDirective1.submitted)  && work2form.get('bankAccountNumber').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="work2form.get('bankAccountNumber').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger" *ngIf="!work2form.get('bankAccountNumber').errors.required && !work2form.get('bankAccountNumber').errors.pattern && 
                                          work2form.get('bankAccountNumber').errors.maxlength ">
                                                        Maximum 16 Characters
                                                    </small>
                                                    <small class="text-danger" *ngIf="!work2form.get('bankAccountNumber').errors.required && !work2form.get('bankAccountNumber').errors.pattern && 
                                          work2form.get('bankAccountNumber').errors.minlength ">
                                                        Minimum 15 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work2form.get('bankAccountNumber').errors.required && work2form.get('bankAccountNumber').errors.pattern ">
                                                        Invalid bank account number
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fa"
                                                        (click)="togglePassword()"
                                                        [ngClass]="{'fa-eye-slash ': !isPasswordVisible, 'fa-eye':isPasswordVisible}"></i>
                                                    <input [type]="isPasswordVisible?'text':'password'"
                                                        autocomplete="on" class="form-control pr-9"
                                                        placeholder="Password" formControlName="password">
                                                    <ng-container
                                                        *ngIf="(workDirective1.submitted)  && work2form.get('password').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work2form.get('password').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work2form.get('password').errors.required && work2form.get('password').errors.minlength">
                                                            Minimum 8 characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work2form.get('password').errors.required && work2form.get('password').errors.maxlength">
                                                            Maximum 50 characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fa"
                                                        (click)="toggleConfirmPassword()"
                                                        [ngClass]="{'fa-eye-slash ': !isConfirmPasswordVisible, 'fa-eye':isConfirmPasswordVisible}"></i>
                                                    <input [type]="isConfirmPasswordVisible?'text':'password'"
                                                        autocomplete="on" class="form-control pr-9"
                                                        placeholder="Confirm Password"
                                                        formControlName="confirmPassword">
                                                    <ng-container
                                                        *ngIf="(workDirective1.submitted)  && work2form.get('confirmPassword').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="work2form.get('confirmPassword').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work2form.get('confirmPassword').errors.required && work2form.get('confirmPassword').errors.NotEqual">
                                                            Password must match
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work2form.get('confirmPassword').errors.required && !work2form.get('confirmPassword').errors.NotEqual && work2form.get('confirmPassword').errors.minlength">
                                                            Minimum 8 characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!work2form.get('confirmPassword').errors.required && !work2form.get('confirmPassword').errors.NotEqual && work2form.get('confirmPassword').errors.maxlength">
                                                            Maximum 50 characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="About you"
                                                    formControlName="aboutYou" rows="7"></textarea>
                                                <ng-container
                                                    *ngIf="(workDirective1.submitted)  && work2form.get('aboutYou').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="work2form.get('aboutYou').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work2form.get('aboutYou').errors.required && work2form.get('aboutYou').errors.minlength">
                                                        Minimum 20 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!work2form.get('aboutYou').errors.required && work2form.get('aboutYou').errors.maxlength">
                                                        Maximum 500 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group mb-md-8 row m-0 mt-md-14 mt-10">
                                                <div class="col-6 px-0 pr-2">
                                                    <button type="button"
                                                        class="btn btn-outline-primary btn-medium w-100 rounded-5 text-capitalize signHeight"
                                                        (click)="wIndex=1">Back</button>
                                                </div>
                                                <div class="col-6 px-0 pl-2">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="work-step-3 col-12" *ngIf="wIndex  == 3">
                                        <form [formGroup]="work3form" #staffDirective2="ngForm"
                                            (ngSubmit)="work3form.valid && signUpWork()">
                                            <div class="form-group">
                                                <div class="border" style="height: 390px;overflow: auto;">
                                                    <div class="font-weight-bold pt-2">Terms &
                                                        Conditions</div>
                                                    <div class="pt-2">AGREEMENT TO TERMS</div><br />
                                                    <!-- <p class="text-justify text-center pl-6 pl-md-0 pr-5 pr-md-0" style="font-size: 14px;">These
                                          Terms and Conditions
                                          constitute a legally binding agreement
                                          made between you, whether personally or on behalf of an entity (“you”) and [business
                                          entity name] (“we,” “us” or “our”), concerning your access to and use of the [website
                                          name.com] website as well as any other media form, media channel, mobile website or mobile
                                          application related, linked, or otherwise connected thereto (collectively, the
                                          “Site”).<br /><br />
                                          You agree that by accessing the Site, you have read, understood, and agree to be bound by
                                          all of these Terms and Conditions. If you do not agree with all of these Terms and
                                          Conditions, then you are expressly prohibited from using the Site and you must discontinue
                                          use immediately.<br /><br />
                                          Supplemental terms and conditions or documents that may be posted on the Site from time to
                                          time are hereby expressly incorporated herein by reference. We reserve the right, in our
                                          sole discretion, to make changes or modifications to these Terms and Conditions -->
                                                    <!-- </p> -->
                                                    <div class="text-justify pl-6 pl-md-0 pr-5 pr-md-0 termsAndCondition"
                                                        style="font-size: 14px;">
                                                        <p style="font-size: 14px;">These Terms and
                                                            Conditions constitute a legally binding
                                                            agreement made between you, whether
                                                            personally or on behalf of an entity
                                                            (&ldquo;you&rdquo;) and Temp
                                                            (&ldquo;we,&rdquo; &ldquo;us&rdquo; or
                                                            &ldquo;our&rdquo;), concerning your access
                                                            to and use of the Temp platform as well as
                                                            any other media form, media channel, mobile
                                                            website or mobile application related,
                                                            linked, or otherwise connected thereto
                                                            (collectively, the &ldquo;Site&rdquo;).</p>

                                                        <!--/Section 0 Preamble/-->
                                                        <h2 class="text-justify pl-6 pl-md-0 pr-5 pr-md-0"
                                                            style="font-size: 14px;">
                                                            <strong>Preamble</strong>
                                                        </h2>
                                                        <ul style="font-size: 14px;padding-left: 0px;">
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    Temp provides an
                                                                    online platform to facilitate
                                                                    Temporary jobs.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    The Job
                                                                    provider/business wishes to engage
                                                                    Temp to find workers and schedule
                                                                    jobs as outlined in a job brief.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    This agreement
                                                                    sets out the terms between the 3
                                                                    parties involved. Temp, the Worker,
                                                                    and the Job provider</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    By agreeing to
                                                                    these terms, the parties confirm
                                                                    that they are bound by this
                                                                    Agreement and any amended term of
                                                                    this Agreement that is subsequently
                                                                    changed in accordance with the terms
                                                                    of this Agreement.</p>
                                                            </li>
                                                        </ul>

                                                        <p><span style="font-size: 14px;"><strong>1.
                                                                    Definition and interpretations as
                                                                    defined in this
                                                                    agreement.</strong></span></p>
                                                        <p><span style="font-size: 14px;"><strong>1.1
                                                                    Definition</strong></span></p>

                                                        <p style="font-size: 14px;">
                                                            <strong>Agreement:</strong> Means this
                                                            agreement including any document signed by
                                                            the Parties that varies or supplements it in
                                                            accordance with its terms.
                                                        </p>
                                                        <p style="font-size: 14px;"><strong>Worker:
                                                            </strong>Means a person engaged to provide
                                                            services operating as an independent
                                                            contractor by a Job provider on the Temp
                                                            platform. This does not include workers of
                                                            Temp Limited.</p>
                                                        <p style="font-size: 14px;"><strong>Job
                                                                provider:</strong> Means you are
                                                            operating as a Job provider looking to
                                                            engage a worker for temporary work
                                                            services.&nbsp;</p>
                                                        <p style="font-size: 14px;"><strong>Business:
                                                            </strong>Means the job provider who is
                                                            looking to hire using the Temp platform</p>
                                                        <p style="font-size: 14px;">
                                                            <strong>Temporary:</strong> Refers to the
                                                            job duration and the nature of the work
                                                            completed through the Temp platform. This
                                                            can be an ongoing engagement between the
                                                            worker and the Job provider that has no end
                                                            date, providing both parties agree to the
                                                            terms.&nbsp;&nbsp;
                                                        </p>
                                                        <p style="font-size: 14px;">
                                                            <strong>Job:</strong> Is what is created by
                                                            the Job provider and completed by the
                                                            worker.&nbsp;
                                                        </p>
                                                        <p style="font-size: 14px;"><strong>Temp
                                                                platform:</strong> Means any system,
                                                            website or platform provided by Temp
                                                            (including the Temp Site) to facilitate the
                                                            engagement between workers and Job
                                                            providers.</p>
                                                        <p style="font-size: 14px;"><strong>Temp:
                                                            </strong>Means Temp Limited and is referred
                                                            to in this Agreement as Temp or the company
                                                        </p>
                                                        <p style="font-size: 14px;"><strong>Job
                                                                Brief/Job description:</strong> This
                                                            outlines the details in terms of what is
                                                            required for the worker to undertake the
                                                            job.</p>
                                                        <p style="font-size: 14px;"><strong>Hourly rate:
                                                            </strong>Defines the remuneration for each
                                                            hour of work completed.&nbsp;</p>
                                                        <p style="font-size: 14px;">
                                                            <strong>Work:</strong> Means services
                                                            performed or provided by a worker at the
                                                            work site in response to a Job Brief.
                                                        </p>
                                                        <p style="font-size: 14px;"><strong>Service
                                                                fee:</strong> Meaning Temp&rsquo;s
                                                            percentage calculated based on the job
                                                            brief. This may change based on the Job
                                                            providers subscription plan.</p>
                                                        <p style="font-size: 14px;"><strong>Work site:
                                                            </strong>Means any location, premises or
                                                            building at which a worker is, will or has
                                                            worked for the purposes of providing the
                                                            Work.</p>
                                                        <p style="font-size: 14px;">
                                                            <strong>Representative:</strong> Means any
                                                            director, officer, employee, agent,
                                                            contractor, or other person representing the
                                                            Job provider.
                                                        </p>
                                                        <p style="font-size: 14px;"><strong>Licenses and
                                                                qualifications:</strong>&nbsp;Temp does
                                                            not verify or investigate the authenticity
                                                            of any prerequisites added under the
                                                            Licenses and qualification section. This
                                                            feature is simply an advertising feature and
                                                            by no means a reliable method of validation.
                                                            It is up to the Job provider to decide the
                                                            authenticity of the workers licenses or
                                                            qualifications.</p>


                                                        <p><span style="font-size: 14px;"><strong>1.2
                                                                    Interpretations</strong></span></p>

                                                        <p style="font-size: 14px;">In this Agreement,
                                                            except where the context otherwise
                                                            requires:&nbsp;</p>
                                                        <ol type="A" style="padding-left:0px;">
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    words in the
                                                                    singular form also include their
                                                                    plural form, and vice versa, and
                                                                    words denoting a gender also include
                                                                    other genders.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    alternative
                                                                    grammatical forms of defined terms
                                                                    have corresponding meanings.&nbsp;
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any reference to
                                                                    money is in New Zealand
                                                                    currency.&nbsp;</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any reference to
                                                                    time is in accordance with New
                                                                    Zealand Daylight Time or New Zealand
                                                                    Standard Time (as applicable).</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any reference to
                                                                    a Party includes their executors,
                                                                    administrators, successors, and
                                                                    permitted assigns and substitutes.
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any reference to
                                                                    a person includes individuals,
                                                                    partnerships, corporations,
                                                                    associations, government entities,
                                                                    or other legal entities.&nbsp;</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any reference to
                                                                    a statute, ordinance, code, or other
                                                                    law includes regulations and other
                                                                    instruments made under it, and any
                                                                    consolidation, amendment,
                                                                    re-enactment, or replacement of
                                                                    them.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    the meaning of
                                                                    general words is not limited by
                                                                    specific examples provided with
                                                                    phrases like "including", "for
                                                                    example", or similar expressions.
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    headings are for
                                                                    convenience only and do not affect
                                                                    the interpretation of the agreement.
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    if a Party
                                                                    comprises of multiple persons, the
                                                                    agreement binds each of them
                                                                    separately and any two or more of
                                                                    them jointly.&nbsp;</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any obligation,
                                                                    representation, or warranty made in
                                                                    favour of multiple persons is for
                                                                    their joint and collective
                                                                    benefit.&nbsp;</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    any agreement,
                                                                    representation, warranty or
                                                                    indemnity made in favour of two or
                                                                    more Parties (including where two or
                                                                    more persons are included in the
                                                                    same defined term) is for their
                                                                    joint and several benefit.&nbsp;</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    no rule of
                                                                    construction shall be interpreted to
                                                                    the disadvantage of a Party merely
                                                                    because they were responsible for
                                                                    preparing this Agreement or any part
                                                                    of it.</p>
                                                            </li>
                                                        </ol>
                                                        <!--/Section 2 General/-->
                                                        <p><span style="font-size: 14px;"><strong>2.1.
                                                                    User Accounts</strong></span><br>To
                                                            use the Temp platform, you must register and
                                                            maintain an active user account with Temp
                                                            (in its capacity as licensor of the Temp
                                                            branded application and websites)
                                                            (&ldquo;Account&rdquo;) (as described in
                                                            section 3 (&lsquo;Contractual relationship)
                                                            of the Temp Terms). Your failure to maintain
                                                            accurate, complete, and up-to-date Account
                                                            information, including having an invalid or
                                                            expired payment method on file, may result
                                                            in your inability to access or use the Temp
                                                            platform. If information provided is found
                                                            to be misleading or false, Temp may
                                                            immediately terminate (as described in
                                                            clause 13) the account and any future
                                                            involvement with the party at Temps sole
                                                            discretion.</p>


                                                        <!--/Section 3 Contractual relationship/-->

                                                        <p><span style="font-size: 14px;"><strong>3.
                                                                    Contractual
                                                                    relationship</strong></span><br><span
                                                                style="font-size: 14px;"><strong>3.1.
                                                                    Temp
                                                                    services</strong></span><br>Temp
                                                            will provide services in accordance with
                                                            these terms and conditions. Temp Services
                                                            constitute the provision of a technology
                                                            platform that enables you, as a user of
                                                            Temp&rsquo;s applications or websites (each,
                                                            an &ldquo;Application&rdquo;) to: (a)
                                                            arrange and schedule certain jobs with
                                                            independent third-party providers of those
                                                            services that have an agreement with Temp or
                                                            its affiliates (&ldquo;Third Party
                                                            Providers&rdquo;), including: (i) Job
                                                            providers providing the job opportunity and
                                                            workers who are providing the work services.
                                                        </p>
                                                        <p><span style="font-size: 14px;"><strong>3.2.
                                                                    Obligations as a
                                                                    worker</strong></span><br>By
                                                            applying for a job, the worker accepts that
                                                            they are entering into an agreement with the
                                                            Business (the job provider) to uphold the
                                                            terms and conditions outlined in this
                                                            agreement.</p>
                                                        <p><strong>3.2.1. </strong>As a worker on the
                                                            Temp platform, you acknowledge that when you
                                                            apply for a job it is at the sole discretion
                                                            of the Job provider whether to hire you or
                                                            not. Once you have applied for a job you
                                                            accept that you have committed to the dates
                                                            set forth in the job description. Failure to
                                                            turn up to an already accepted job without
                                                            valid reason may result in a ban from the
                                                            Temp platform. This is at the sole
                                                            discretion of Temp which will carry out an
                                                            investigation after the job period has
                                                            ended. Temp will only investigate issues
                                                            that have been reported. Workers must ensure
                                                            they are of legal age required to complete
                                                            the job. This includes being at least 18
                                                            years or older when working with Alcohol and
                                                            at least 21 years of age when working on a
                                                            premises with gambling facilities.</p>
                                                        <p><strong>3.2.2.</strong> As a worker you
                                                            operate as an independent contractor which
                                                            means you have no guarantee of work, and the
                                                            business (the job provider) is not obligated
                                                            to handle any Tax, ACC, or employment
                                                            benefit on your behalf. You are obligated by
                                                            law to pay tax on all your earnings that you
                                                            have made by using the Temp platform. Temp
                                                            does not deduct tax from workers earnings.
                                                            Workers are to make their own arrangements
                                                            to pay tax on earnings made through the Temp
                                                            platform.</p>
                                                        <p><strong>3.2.3. </strong>Workers are not
                                                            employees of Temp nor are they paid by Temp.
                                                            Temp acts as a digital software provider
                                                            that connects Job providers and workers.</p>
                                                        <p><strong>3.2.4.</strong> Workers agree that
                                                            they must complete the job to the best of
                                                            their ability.</p>
                                                        <p><span style="font-size: 14px;"><strong>3.3.
                                                                    Obligations as a Job
                                                                    provider</strong></span><br>By
                                                            posting a job the Job provider agrees that
                                                            they are entering into an agreement with the
                                                            worker that is set forth in this agreement.
                                                        </p>
                                                        <p><strong>The Job provider must:</strong></p>
                                                        <p><strong>3.3.1.</strong> provide safe and
                                                            suitable working conditions that are in
                                                            accordance with the Health and Safety at
                                                            Work Act 2015.</p>
                                                        <p><strong>3.3.2.</strong> provide Temp with
                                                            full and accurate information about the
                                                            service requirements relevant to the Job
                                                            Brief, and all other information and
                                                            assistance reasonably necessary to enable
                                                            Temp to provide services and workers to
                                                            perform their work; This should include the
                                                            nature of the task in which the Job provider
                                                            is hiring to complete and any safety
                                                            equipment that might be used/needed.</p>
                                                        <p><strong>3.3.3. </strong>not allocate tasks or
                                                            responsibilities to the workers or require
                                                            the workers to perform or participate in
                                                            work other than in accordance with the
                                                            relevant Job description.</p>
                                                        <p><strong>3.3.4. </strong>Immediately advise
                                                            Temp in writing once it becomes aware that
                                                            the work, role, responsibilities, or work
                                                            conditions the Job provider sets for a
                                                            worker at the time when the work is being
                                                            performed do not match the role
                                                            classification that the Job provider
                                                            selected when creating the Job Brief;</p>
                                                        <p><strong>3.3.5. </strong>Ensure that workers
                                                            will be covered by the following insurance
                                                            policies, or other suitable and permissible
                                                            statutory indemnity or self-insurance
                                                            arrangements, whilst performing their Work
                                                            at the Work Site:</p>
                                                        <p><strong>3.3.5.1.</strong> public liability,
                                                            professional indemnity, if relevant to the
                                                            type of Work to be performed.</p>
                                                        <p><br><strong>3.3.5.2. </strong>compulsory
                                                            third party motor vehicle insurance, and
                                                            fully comprehensive motor vehicle insurance
                                                            (including third party personal injury, own
                                                            damage, third party property damage
                                                            liability and third party personal injury
                                                            gap) for any vehicles operated by a worker;
                                                        </p>
                                                        <p>Failure to meet these requirements or
                                                            misrepresentation of the skill level or
                                                            workload of the job may result in a ban from
                                                            the Temp platform.</p>

                                                        <!--/Section 4 Ratings/-->

                                                        <p><span style="font-size: 14px;"><strong>4.
                                                                    Ratings</strong></span><br>By
                                                            entering a job as the job provider or as the
                                                            worker you acknowledge that you will be
                                                            rated at the end of the job period. Quality
                                                            of ratings is of the opinion of each party
                                                            and should comply with the terms outlined in
                                                            this agreement. It is important to know that
                                                            it is not Temps obligation to censor
                                                            ratings, Temp may choose to intervene at its
                                                            sole discretion if job feedback includes
                                                            inappropriate language or unreasonable
                                                            criticism.</p>


                                                        <p><span style="font-size: 14px;"><strong>4.1.
                                                                    Ratings for Job
                                                                    providers</strong></span><br><strong>4.1.1
                                                                Rating your worker</strong><br>All
                                                            ratings should be an accurate representation
                                                            of the workers efforts and commitment to the
                                                            job.</p>
                                                        <p><strong>4.1.2. Receiving a rating as a Job
                                                                provider</strong><br>As a Job provider
                                                            you accept that by hiring through the Temp
                                                            platform you acknowledge you will be rated
                                                            on your performance as a Job provider.
                                                            Ratings are completely the opinion of the
                                                            workers you hire and what keeps the platform
                                                            fair. There is a possibility for you to
                                                            receive an unsatisfactory rating from a
                                                            worker and it is not the job of Temp to
                                                            influence, edit or change this unless Temp
                                                            considers that the rating was unfairly or
                                                            dishonestly provided.</p>

                                                        <p><span style="font-size: 14px;"><strong>4.2.
                                                                    Ratings for
                                                                    workers</strong></span><br><strong>4.2.1.Rating
                                                                your Job provider</strong><br>All
                                                            ratings should be an accurate representation
                                                            of the Job providers efforts to create a
                                                            fair and suitable work environment.</p>
                                                        <p><strong>4.2.2. Receiving a rating as a
                                                                worker</strong><br>As a worker you
                                                            acknowledge that you will be rated on your
                                                            performance for each job you complete
                                                            through the Temp platform. While ratings are
                                                            meant to be an accurate representation of
                                                            your effort and commitment. There is a
                                                            possibility for you to receive an
                                                            unsatisfactory rating. It is not the job of
                                                            Temp to influence, edit or change this
                                                            unless Temp considers that it was unfairly
                                                            or dishonestly provided.</p>

                                                        <!--/Section 5 Disputes/-->

                                                        <p><span style="font-size: 14px;"><strong>5.
                                                                    Disputes</strong></span><br>Any
                                                            disputes over job quality, pay or other
                                                            contexts are to be handled between the
                                                            business (Job provider) and the worker (the
                                                            services provider). Temp acts as the digital
                                                            facilitator to connect both parties for work
                                                            opportunities and does not have any
                                                            involvement nor is obligated to participate
                                                            in any dispute resolution.&nbsp;</p>
                                                        <p>Where there is a dispute between the Job
                                                            provider and the worker, funds that have
                                                            already been paid to Temp will be refunded
                                                            to the Job provider.</p>

                                                        <!--/Section 6. Cancellation of Job Brief by Job provide/-->

                                                        <p><span style="font-size: 14px;"><strong>6.
                                                                    Cancellation of Job Brief by Job
                                                                    provider</strong></span><br>The Job
                                                            Provider may (i) cancel a Job Brief or any
                                                            part of it, or (ii) make any amendment to
                                                            the Job Brief which causes the shift of one
                                                            or more worker(s) to be canceled, without
                                                            any liability at any time, provided that
                                                            such cancellation or amendment is done using
                                                            the Temp Platform, 4 hours prior to the
                                                            expected commencement time of the affected
                                                            shift(s) by a worker(s) under the Job Brief
                                                            (&ldquo;Acceptable Cancellation
                                                            Period&rdquo;).</p>

                                                        <!--/Section 7 Payments/-->

                                                        <p><span style="font-size: 14px;"><strong>7.
                                                                    Payments</strong></span><br><span
                                                                style="font-size: 14px;"><strong>7.1.
                                                                    Hourly
                                                                    Rates</strong></span><br>unless
                                                            there is a written agreement between Temp
                                                            and the job provider. The Job provider is
                                                            obligated to pay Temp for each Job
                                                            description an amount equal to the sum of
                                                            the components specified in sections 7.1.1
                                                            to 7.1.5.</p>
                                                        <p><strong>7.1.1. </strong>The total gross
                                                            payment to be paid to the worker(s) also
                                                            known at the Worker(s) payment.</p>
                                                        <p><strong>7.1.2.&nbsp;</strong>The Service Fee
                                                            will be calculated by multiplying the sum of
                                                            all components from Clause 7.1.1 by the Job
                                                            providers prescribed rate, unless otherwise
                                                            agreed in writing by Temp.</p>
                                                        <p><strong>7.1.3.&nbsp;</strong>(the total sum
                                                            payable by the Job provider under a Job
                                                            description as set out in 7.1.1 to 7.1.2
                                                            above is collectively known as the
                                                            &ldquo;Rate&rdquo;).</p>
                                                        <p><strong>7.1.4.&nbsp;</strong>The Job provider
                                                            will be shown an estimated rate applicable
                                                            to the Job description after they have
                                                            filled out the relevant information of a Job
                                                            description (but prior to posting the Job
                                                            via the Temp Platform). The Job provider may
                                                            request further information from Temp
                                                            regarding the estimated Rate (including its
                                                            breakdown or calculation). The Job provider
                                                            accepts that the estimated Rate is an
                                                            approximation based on the information
                                                            provided by the Job provider at the time
                                                            when the Job description is filled out and/
                                                            or posted. The actual Rate payable by the
                                                            Job provider will be confirmed after the
                                                            Services and Work by the worker(s) are
                                                            performed, and/ or after the timesheet is
                                                            submitted and verified pursuant to Clause
                                                            7.2.</p>

                                                        <p><span style="font-size: 14px;"><strong>7.2.
                                                                    Variation of rate</strong></span>
                                                        </p>
                                                        <p><strong>7.2.1. </strong>The Job provider
                                                            acknowledges that the total workers payment
                                                            and rate components referred to in Clause
                                                            7.1 are subject to change from time to time
                                                            as a result of a variation of the estimated
                                                            job duration. The Job provider agrees that
                                                            such changes shall become effective and
                                                            apply to the Rate from the date the changes
                                                            are applied. Excluding any existing or
                                                            outstanding Job Description that has already
                                                            been accepted by both Parties, Temp will use
                                                            its best endeavors to notify the Job
                                                            provider of any such expected changes with
                                                            reasonable notice. The Job provider agrees
                                                            to pay Temp the increased Rate from the
                                                            applicable effective date, including any
                                                            proportional change to the amount of Service
                                                            Fee as a result of such changes.</p>

                                                        <p><span style="font-size: 14px;"><strong>7.3.
                                                                    Subscriptions</strong></span><br>Unless
                                                            otherwise agreed by written notice, the Job
                                                            provider is responsible for paying Temp the
                                                            subscription fee that corresponds with the
                                                            subscription plan they have selected.</p>
                                                        <p><strong>7.3.1. Subscriptions Fees and
                                                                payment</strong><br>Immediately upon
                                                            receipt of the invoice, the Job provider
                                                            gives authorization to Temp to bill their
                                                            credit card upon subscription for the
                                                            Service and any subsequent renewals. Payment
                                                            obligations may not be canceled within the 1
                                                            year subscription period. Subscription fees
                                                            paid are non-refundable. The Job provider
                                                            will pay the Fees using an accepted payment
                                                            method as specified in the invoice or on
                                                            Temp&rsquo;s website. Unless otherwise
                                                            indicated, the Job Provider's subscription
                                                            to the services will automatically renew for
                                                            a Subscription Term in accordance with the
                                                            renewal terms and conditions set forth in
                                                            clause 7.3.5. During the Term of the
                                                            Subscription Period, the Job Provider is not
                                                            permitted to reduce their subscription plan.
                                                        </p>
                                                        <p><strong>7.3.2. Late Payments.</strong><br>If
                                                            undisputed Fees are more than thirty (30)
                                                            days overdue, then following written
                                                            notification from Temp, Temp may suspend the
                                                            Job provider&rsquo;s access to the Temp
                                                            platform, including, without limitation, the
                                                            Job provider&rsquo;s account, until such
                                                            unpaid fees are paid in full.</p>
                                                        <p><strong>7.3.3. Term, Termination and
                                                                Suspension</strong><br>Subscriptions are
                                                            effective at the date of enrollment in any
                                                            subscription plan on the Temp website and
                                                            will continue for a period of 1 year.</p>
                                                        <p><strong>7.3.4. Subscription
                                                                plans</strong><br>All plans are
                                                            contracted for 1 year from the date of
                                                            purchase. Job providers can choose to pay
                                                            the yearly cost upfront to save 10% percent
                                                            off the total subscription fee. Temp may
                                                            choose to additionally offer further
                                                            discounts at its sole discretion.</p>
                                                        <p style="font-size: 14px;"><strong>Available
                                                                plans</strong></p>
                                                        <ol>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    Basic Plan is
                                                                    $50 monthly, plus a 10% service fee.
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    Agency Plan is
                                                                    $75 monthly, plus a 5% service fee
                                                                </p>
                                                            </li>
                                                        </ol>
                                                        <p style="font-size: 14px;">After the Job
                                                            provider has entered into a subscription
                                                            plan, they will not be eligible to switch to
                                                            an annual subscription plan for any savings.
                                                        </p>
                                                        <p style="font-size: 14px;">A Basic Plan can be
                                                            upgraded to an Agency plan at any time.
                                                            Payment will be adjusted accordingly within
                                                            the next billing cycle. If the Basic plan
                                                            was prepaid annually then the Job provider
                                                            will be charged for the remaining months
                                                            within the current contract at the Agency
                                                            annual rate. e.g., if the Job provider
                                                            upgrades with 6 months left of their Basic
                                                            annual plan to an Agency annual plan, then
                                                            they would be charged $135.</p>
                                                        <p style="font-size: 14px;"><strong>7.3.5.
                                                                Renewal.&nbsp;</strong><br>The
                                                            Subscription Plans will automatically renew
                                                            for a period equal to the previous
                                                            Subscription Term unless a party provides
                                                            written notice of non-renewal at least
                                                            thirty (30) days prior to the expiration of
                                                            the current term. At the beginning of each
                                                            Subscription Term, Temp may increase the
                                                            Fees, and any fees for a renewed term will
                                                            be due on the date of renewal.</p>


                                                        <p><span style="font-size: 14px;"><strong>7.4
                                                                    Refunds</strong></span><br>Refunds
                                                            will only be given for worker discrepancies
                                                            or unfulfilled jobs and will not be given
                                                            for subscriptions regardless of the usage.
                                                        </p>
                                                        <p><strong>7.4.1. Refund for worker
                                                                discrepancies</strong><br>Refunds will
                                                            be issued if the worker does not fulfill the
                                                            total agreed hours. The Job provider will
                                                            accurately record the workers hours in the
                                                            Timesheet. (Clause 8)</p>
                                                        <p><strong>7.4.2.&nbsp;</strong>Refunds will
                                                            also be automatically sent if the job is not
                                                            filled in the posting period. Refunds will
                                                            be paid out within 10 days of the end of the
                                                            posting period.</p>
                                                        <p><span style="font-size: 14px;"><strong>7.5.
                                                                    Payouts</strong></span><br>Payment
                                                            processing services for Workers on Temp are
                                                            provided by Stripe and are subject to the
                                                            <span style="color: rgb(0, 176, 116);"><a
                                                                    style="color: rgb(0, 176, 116);"
                                                                    target="_blank"
                                                                    href="https://stripe.com/connect-account/legal/full">Stripe
                                                                    Connected Account
                                                                    Agreement</a>,</span> which includes
                                                            the <span style="color: rgb(0, 176, 116);"><a
                                                                    style="color: rgb(0, 176, 116);"
                                                                    target="_blank"
                                                                    href="https://stripe.com/legal">Stripe
                                                                    Terms of Service </a>
                                                            </span>(collectively, the &ldquo;Stripe
                                                            Services Agreement&rdquo;). By agreeing to
                                                            [this agreement / these terms / and so on]
                                                            or continuing to operate as a Worker on
                                                            Temp, you agree to be bound by the Stripe
                                                            Services Agreement, as the same may be
                                                            modified by Stripe from time to time. As a
                                                            condition of Temp enabling payment
                                                            processing services through Stripe, you
                                                            agree to provide Temp accurate and complete
                                                            information about you and your business, and
                                                            you authorize Temp to share it and
                                                            transaction information related to your use
                                                            of the payment processing services provided
                                                            by Stripe.
                                                        </p>
                                                        <p>Payouts will be made on a weekly basis if the
                                                            job extends longer than one week. In the
                                                            case that the job is shorter than one week,
                                                            the payout will be made at the end of the
                                                            job. The approval of payouts is held by the
                                                            Job Provider, and they will release the
                                                            payout when worker(s) submits a completed
                                                            timesheets (refer to clause 8.2). If the
                                                            timesheets are not approved within three
                                                            days of the job's completion, the Job
                                                            Provider agrees the payout will be
                                                            calculated based on the worker's hours
                                                            entered. Note, Temp does not have authority
                                                            over payouts.</p>
                                                        <p><strong>Workers must ensure:</strong></p>
                                                        <p><strong>7.5.1.&nbsp;</strong>That Temp has
                                                            been supplied the correct bank account
                                                            information. Payouts cannot be amended or
                                                            revised and it is not the responsibility of
                                                            Temp to track down missing funds.</p>
                                                        <p><strong>7.5.2. </strong>They pay tax on
                                                            earnings made through the Temp platform. As
                                                            workers operate as independent contractors,
                                                            they are responsible for paying their own
                                                            taxes.</p>

                                                        <!--/Section 8. Timesheets</-->

                                                        <p><span style="font-size: 14px;"><strong>8.
                                                                    Timesheets</strong></span><br><span
                                                                style="font-size: 14px;"><strong>8.1.
                                                                    Timesheet
                                                                    entry</strong></span><br>Workers are
                                                            obligated to record their hours accurately.
                                                            This includes their start and finish times
                                                            and their break times if applicable. If a
                                                            worker fails to record their hours before
                                                            the job completion date, then it is at the
                                                            discretion of the Job provider to fill it
                                                            out for them.</p>
                                                        <p><span style="font-size: 14px;"><strong>8.2.
                                                                    Timesheet
                                                                    approval</strong></span><br>Workers
                                                            will track their time worked for the Job
                                                            Provider and submit a timesheet for approval
                                                            via the Temp Platform, unless otherwise
                                                            agreed in writing by Temp. The worker(s)
                                                            must submit their hours, and the Job
                                                            Provider must approve their timesheets
                                                            weekly or at the completion of the job if
                                                            shorter than one week. &nbsp;The Job
                                                            provider shall verify and approve timesheets
                                                            weekly on Fridays unless the job duration is
                                                            less than 5 days, then it must be approved
                                                            once the job is completed. Timesheets must
                                                            be approved within the Timesheet approval
                                                            period of 3 days. If the Job provider does
                                                            not dispute or edit the workers hours within
                                                            the Timesheet Approval Period, the Job
                                                            Provider agrees to the payout calculated
                                                            based on the worker's hours entered.</p>
                                                        <p><span style="font-size: 14px;"><strong>8.3.
                                                                    Timesheet
                                                                    discrepancies</strong></span><br>Workers
                                                            can complete additional hours with the Job
                                                            providers approval. All additional hours
                                                            should be recorded in the Timesheet on the
                                                            Temp platform. If the Job provider agrees to
                                                            the additional hours, the Job provider will
                                                            be charged an additional fee for the extra
                                                            hours. In the event the worker works less
                                                            than the agreed upon hours, then the Job
                                                            provider will be entitled to a refund
                                                            (clause 7.4).</p>

                                                        <!--/Section 9. Promotions</-->

                                                        <p><span style="font-size: 14px;"><strong>9.
                                                                    Promotions</strong></span><br>Temp
                                                            is the
                                                            &ldquo;<strong>Promoter</strong>&rdquo;
                                                            responsible for these promotions.</p>
                                                        <p><span style="font-size: 14px;"><strong>9.1
                                                                    Disqualified
                                                                    Participants:</strong></span><br>The
                                                            following individuals, referred to as
                                                            "Disqualified Participants," are ineligible
                                                            to participate in these promotions:</p>
                                                        <p><strong>9.1.1. </strong>All current Temp
                                                            employees, all employees who work for
                                                            associated marketing or advertising
                                                            agencies, and their immediate families.</p>
                                                        <p><strong>9.1.2.</strong> All individuals under
                                                            the age of 18 years if the prize involves
                                                            air travel or any other element that is
                                                            illegal to supply to a person under 18 years
                                                            of age.</p>
                                                        <p><strong>9.1.3. </strong>All individuals who
                                                            have won a prize from a Temp promotion
                                                            within the last 30 days. If the previous
                                                            prize was valued at over $1,000, the winner
                                                            must wait 90 days before entering further
                                                            Temp promotions again.</p>
                                                        <p><span style="font-size: 14px;"><strong>9.2.
                                                                    Promotions &amp; Giveaways
                                                                    Entry</strong></span><br><strong>9.2.1.
                                                            </strong>These rules, referred to as "the
                                                            Rules," apply to all promotions and
                                                            competitions by Temp, referred to as
                                                            "Promotion," whether conducted online or
                                                            in-person through any medium, including
                                                            while completing a job or online through a
                                                            connected device. The Rules are subject to
                                                            change.</p>
                                                        <p><strong>9.2.2.</strong> If a specific
                                                            Promotion has its own set of rules or terms,
                                                            referred to as "Specific Rules," those
                                                            Specific Rules will take precedence in case
                                                            of any inconsistencies with the Rules.</p>
                                                        <p><strong>9.2.3.</strong> Unless otherwise
                                                            specified in the Specific Rules,
                                                            registration, entry, or voting is limited to
                                                            one per person. If multiple registrations,
                                                            entries, or votes are allowed, each must be
                                                            submitted separately.</p>
                                                        <p><strong>9.2.4. </strong>By entering the
                                                            Promotion, participants acknowledge that
                                                            they have read and understood the Rules and
                                                            the Specific Rules (if applicable) and have
                                                            the necessary authority to enter the
                                                            Promotion (such as permission from the bill
                                                            payer or device owner).</p>
                                                        <p><strong>9.2.5.</strong> No purchase is
                                                            necessary to participate or win, unless
                                                            stated otherwise in the Specific Rules.</p>
                                                        <p><strong>9.2.6.</strong> The Promotion is open
                                                            to New Zealand residents and those who have
                                                            the right to work or operate as independent
                                                            contractors. Disqualified Participants may
                                                            not enter the Promotion.</p>
                                                        <p><strong>9.2.7. </strong>Temp reserves the
                                                            right to exclude any individual from
                                                            participating in the Promotion for
                                                            reasonable grounds. Temp also reserves the
                                                            right to refuse to award any prize to a
                                                            winner if Temp, in its sole discretion,
                                                            determines that the winner violated the
                                                            Rules (including the Specific Rules), gained
                                                            an unfair advantage, or won using fraudulent
                                                            means.</p>
                                                        <p><strong>9.2.8.</strong> Temp reserves the
                                                            right to refuse to award any prize to an
                                                            entrant who Temp decides (in its sole
                                                            discretion) has violated the Rules
                                                            (including the Specific Rules), gained
                                                            unfair advantage in participating in the
                                                            Promotion or won using fraudulent means.</p>
                                                        <p><strong>9.2.9.</strong> By participating,
                                                            entrants grant Temp exclusive permission to
                                                            use their names, characters, photographs,
                                                            videos, voices, and likeness in connection
                                                            with the Promotion and for future
                                                            promotional and marketing purposes. Entrants
                                                            waive any claims to royalties, rights, or
                                                            remuneration for such use.</p>
                                                        <p><strong>9.2.10.</strong> All personal
                                                            information provided by participants must be
                                                            valid and up-to-date. Temp may use this
                                                            information for the Promotion and future
                                                            promotional and marketing purposes in
                                                            accordance with its Privacy Policy
                                                            (available on the website www.temp.org.nz),
                                                            unless otherwise directed by the participant
                                                            at the time of entry.</p>
                                                        <p><span style="font-size: 14px;"><strong>9.3.
                                                                    Winning the Prize</strong></span>
                                                        </p>
                                                        <p><strong>9.3.1.</strong> Only the individual
                                                            who entered the Promotion or competition can
                                                            win the prize, referred to as the "Winner,"
                                                            unless otherwise specified by Temp in the
                                                            Specific Rules.</p>
                                                        <p><strong>9.3.2. </strong>The Winner will be
                                                            determined according to the Rules or the
                                                            Specific Rules, or if not specified, by Temp
                                                            acting as the judge, referred to as the
                                                            "Judge."</p>
                                                        <p><strong>9.3.3.</strong> The Judge's
                                                            determination of the Winner will be final,
                                                            and no correspondence will be entered into.
                                                        </p>
                                                        <p><strong>9.3.4. </strong>The Winner will be
                                                            notified by either email, phone (voice or
                                                            text), mail, or in-person, and must be
                                                            available for all required publicity by
                                                            Temp. If the Winner cannot be reached after
                                                            three attempts by phone or if mail is
                                                            returned, Temp will select another winner.
                                                            If the prize is not collected within two
                                                            months of being announced, it will be
                                                            forfeited. Note that three attempts will be
                                                            made to contact the Winner.</p>
                                                        <p><strong>9.3.5.</strong> Where the Winner is
                                                            required to claim the prize in person, they
                                                            must provide government issued photo
                                                            identification such as a driver's license,
                                                            or passport.</p>
                                                        <p><strong>9.3.6.</strong> The recipient of the
                                                            Prize assumes full responsibility and bears
                                                            all risks associated with it, and
                                                            indemnifies Temp against any claims arising
                                                            from accidents, injuries, property damage,
                                                            or loss of life that may occur in connection
                                                            with the Prize. The Winner is also
                                                            responsible for all costs, including
                                                            insurance, taxes, and other expenses that
                                                            may be associated with the Prize. If there
                                                            are any terms and conditions or limitations
                                                            associated with the Prize, the Winner
                                                            accepts them as a condition of receiving the
                                                            Prize.</p>

                                                        <!--/Section 10. Exclusions of Liability</-->

                                                        <p><span style="font-size: 14px;"><strong>10.
                                                                    Exclusions of
                                                                    Liability</strong></span><br>Neither
                                                            Party shall be held liable, in contract,
                                                            tort, under statute or otherwise, for any
                                                            consequential or indirect losses sustained
                                                            by the other Party, including but not
                                                            limited to loss of investment, loss of
                                                            contract, loss of production, loss of
                                                            profits, loss of time, or loss of use.</p>
                                                        <p><span style="font-size: 14px;"><strong>10.1
                                                                    Job provider</strong></span></p>
                                                        <p><strong>10.1.1&nbsp;</strong>The Job provider
                                                            acknowledges that Temp does not hold motor
                                                            vehicle or any other relevant insurance for
                                                            vehicles that are registered or required at
                                                            law to be registered and which are not owned
                                                            by Temp. Temp shall not be held liable, and
                                                            the Job provider shall indemnify Temp and
                                                            workers on the Temp platform against any
                                                            loss (including liability under any
                                                            indemnity or claims by third parties)
                                                            related to a Job Brief, to the extent that
                                                            the loss (i) is arising out of or in
                                                            connection with driving or operating any
                                                            motor or commercial vehicle(s) by the
                                                            worker(s) in the course of their Work; or
                                                            (ii) would ordinarily and commonly be
                                                            covered by a motor vehicle insurance policy
                                                            or any such equivalent or relevant
                                                            insurances. If a worker chooses to use their
                                                            personal Motor vehicle for a job brief this
                                                            is at the Workers discretion, and they will
                                                            assume full liability.</p>
                                                        <p><strong>10.1.2.</strong> The Job provider
                                                            acknowledges that the worker will be working
                                                            under the Job provider's supervision,
                                                            control, direction, and instruction at the
                                                            Job Site. Due to this reason, the Job
                                                            provider agrees that Temp will not be liable
                                                            to the Job provider for, and the Job
                                                            provider will indemnify Temp against, any
                                                            loss of whatsoever nature or kind, however
                                                            caused by one or more of the workers
                                                            (including by their negligence) whilst they
                                                            are working for the Job provider under a Job
                                                            Brief.</p>
                                                        <p><span style="font-size: 14px;"><strong>10.2
                                                                    Workers</strong></span></p>
                                                        <p><strong>10.2.1</strong> The worker
                                                            acknowledges that they act as an independent
                                                            contractor and are responsible for paying
                                                            their own income tax. It is not the
                                                            responsibility of Temp or the Job provider
                                                            to pay any income tax, ACC or superannuation
                                                            benefit. The worker also acknowledges that
                                                            Temp will not be responsible for any Tax
                                                            fines or penalties incurred due to
                                                            undeclared income or unpaid tax.</p>
                                                        <p><strong>10.2.2.</strong> Temp and the Job
                                                            provider will not be accountable for any
                                                            damage to the worker&rsquo;s personal
                                                            property when carrying out a Job. It is the
                                                            choice of the worker whether to use a
                                                            personal motor vehicle or other personal
                                                            equipment that may be needed to complete the
                                                            job. This should be outlined in the job
                                                            brief prior to the worker accepting the job.
                                                        </p>
                                                        <p><strong>10.2.3.</strong> The worker
                                                            acknowledges that they will be working under
                                                            the Job provider's supervision, control,
                                                            direction, and instruction at the Work Site.
                                                            For this reason, the worker agrees that Temp
                                                            will not be liable to the worker for, and
                                                            the worker will indemnify Temp against, any
                                                            loss of whatsoever nature or kind, however
                                                            caused by the worker (including by their
                                                            negligence) whilst they are working for the
                                                            Job provider under a Job Brief.</p>

                                                        <!--/Section 11. Intellectual Property</-->

                                                        <p><span style="font-size: 14px;"><strong>11.
                                                                    Intellectual
                                                                    Property</strong></span></p>
                                                        <p><strong>11.1</strong> Temp agrees that all
                                                            Intellectual property and other information
                                                            provided by the Job provider to Temp remains
                                                            the property of the Job provider.</p>
                                                        <p><strong>11.2.</strong> The Job provider
                                                            acknowledges and agrees that Temp continues
                                                            to own all of its own Intellectual Property
                                                            existing at the date of this Agreement or
                                                            coming into existence during the Term of
                                                            this Agreement (including any improvement to
                                                            such Intellectual Property developed during
                                                            the Term of the Agreement). Temp grants the
                                                            Job provider a limited, revocable right to
                                                            access and use the Temp Platform for the
                                                            purpose and to the extent as allowed under
                                                            this Agreement during the Term.</p>

                                                        <!--/Section 12. Notices</-->

                                                        <p><span style="font-size: 14px;"><strong>12.
                                                                    Notices</strong></span></p>
                                                        <p><strong>12.1. </strong>All mention of notices
                                                            under this agreement are to be given in
                                                            writing.</p>
                                                        <p><strong>12.2. </strong>Notices must be sent
                                                            electronically in the form of email to the
                                                            contact email set out in this
                                                            clause.<strong><br></strong></p>
                                                        <p style="font-size: 14px;">(Email):<span
                                                                style="color: rgb(0, 176, 116);"> <a
                                                                    style="color: rgb(0, 176, 116);"
                                                                    href="mailto:support@temp.org.nz">support@temp.org.nz</a>&nbsp;</span>
                                                        </p>
                                                        <p style="font-size: 14px;">Job provider and
                                                            Workers: (Address &amp; Email) as registered
                                                            through the Temp platform or updated through
                                                            written notice.</p>
                                                        <p><strong>12.3. </strong>The Job provider and
                                                            Worker are responsible for providing Temp
                                                            with their current email address. In the
                                                            event that the last email address provided
                                                            to Temp is invalid, or for any reason is not
                                                            capable of delivery to Temp, Temp will not
                                                            recognize that notice has been given.</p>
                                                        <p><strong>12.4.</strong> The Job provider and
                                                            worker given notice under this Agreement by
                                                            Temp will constitute effective notice by
                                                            Temp dispatching of an email containing such
                                                            notice.</p>

                                                        <!--/Section 13.Termination </-->

                                                        <p><span style="font-size: 14px;"><strong>13.
                                                                    Termination</strong></span><br><span
                                                                style="font-size: 14px;"><strong>13.1
                                                                    Immediate
                                                                    termination</strong></span><br>The
                                                            Job Provider and the Worker may immediately
                                                            terminate this Agreement by written notice
                                                            to the other if any of the following occurs:
                                                        </p>
                                                        <ol>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    A termination as
                                                                    a result of a Party breaching any
                                                                    term of the Agreement and failing to
                                                                    remedy the breach within 7 days of
                                                                    being requested to do so, or if the
                                                                    breach is irreparable.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    If a Job
                                                                    provider becomes Insolvent.&nbsp;
                                                                </p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    If a Job
                                                                    provider suspends or ceases, or
                                                                    threatens to suspend or cease, a
                                                                    substantial part of its business
                                                                    operations.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    Termination of
                                                                    this agreement does not constitute a
                                                                    cancelation of subscription plans
                                                                    nor an in progress job brief.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    If a worker
                                                                    suspends or ceases, or threatens to
                                                                    suspend or cease, a substantial part
                                                                    of their services.</p>
                                                            </li>
                                                            <li style="font-size: 14px;" aria-level="1">
                                                                <p style="font-size: 14px;" role="presentation">
                                                                    If a Job
                                                                    provider or worker engages in fraud,
                                                                    dishonesty, or any other serious
                                                                    misconduct, immediate termination
                                                                    may be initiated.</p>
                                                            </li>
                                                        </ol>
                                                        <p><strong>13.2. </strong>Additionally, Temp may
                                                            terminate the Agreement by providing written
                                                            notice to the Job provider if it reasonably
                                                            believes that the Work Site is or has become
                                                            unsafe for any reason.</p>
                                                        <p><span style="font-size: 14px;"><strong>13.3.
                                                                    Termination without
                                                                    Cause</strong></span><br>Either
                                                            Party may terminate this Agreement at any
                                                            time but will need to provide reasons and
                                                            must give 14 days written notice.</p>

                                                        <!--/Section 14. Force Majeure</-->

                                                        <p><span style="font-size: 14px;"><strong>14.
                                                                    Force Majeure</strong></span></p>
                                                        <p><strong>14.1.&nbsp;</strong>If a Party is
                                                            prevented from or delayed in performing an
                                                            obligation under this Agreement (including
                                                            under any Job Brief, other than an
                                                            obligation to pay invoices pursuant to
                                                            Clause 7.3.2) by a Force Majeure Event, then
                                                            the obligation is suspended during, but for
                                                            no longer than, the period the Force Majeure
                                                            Event continues and such further period as
                                                            reasonable in the circumstances. Neither
                                                            Party shall be liable for any such
                                                            suspension, delay or disruption to the
                                                            performance of any obligations as a result
                                                            of such Force Majeure Event.</p>
                                                        <p><strong>14.2. </strong>The Party that is
                                                            prevented from or delayed in performing the
                                                            obligation must as soon as reasonably
                                                            possible, notify the other Party of its
                                                            inability to perform the obligation due to
                                                            the Force Majeure Event. This can be done by
                                                            using the report function either within the
                                                            job page for the Worker or in the applicant
                                                            list for the Job provider.</p>

                                                        <!--/Section 15 Governing Law</-->

                                                        <p><span style="font-size: 14px;"><strong>15.
                                                                    Governing Law</strong></span></p>
                                                        <p>This Agreement will be governed by the laws
                                                            of New Zealand and the Parties irrevocably
                                                            submit to the non-exclusive jurisdiction of
                                                            the courts in that jurisdiction.</p>

                                                        <!--/Section 16 Right to work</-->

                                                        <p><span style="font-size: 14px;"><strong>16.
                                                                    Right to work</strong></span></p>
                                                        <p>All workers on the temp platform must hold
                                                            New Zealand residency or have the necessary
                                                            Visa or associated documentation to perform
                                                            work lawfully. A list of acceptable visas
                                                            can be found at www.immigration.govt.nz. All
                                                            workers must comply with the Immigration Act
                                                            2009 and hold the applicable documentation
                                                            to perform work operating as an independent
                                                            contractor. Failure to prove or provide such
                                                            documentation will result in a ban from the
                                                            Temp platform.</p>

                                                        <!--/Section 17 Temps Geofence</-->

                                                        <p><span style="font-size: 14px;"><strong>17.
                                                                    Temp&rsquo;s
                                                                    Geofence</strong></span></p>
                                                        <p>Temp uses geofencing technology to ensure
                                                            that our website is only accessible to users
                                                            who are within specific geographic
                                                            boundaries. Geofencing is a location-based
                                                            service that allows us to define virtual
                                                            geographic boundaries, enabling us to
                                                            restrict access to our website to users who
                                                            are physically located within those
                                                            boundaries.&nbsp;</p>
                                                        <p>By using Temp, you consent to our use of
                                                            geofencing technology to restrict access to
                                                            our website based on your location.&nbsp;
                                                        </p>
                                                        <p>We only collect and use location data to
                                                            ensure that you are within the designated
                                                            geographic boundaries to access our website.
                                                            We do not use this data for any other
                                                            purposes or share it with any third
                                                            parties.&nbsp;</p>
                                                        <p>If you have any questions or concerns about
                                                            our use of geofencing technology, please
                                                            contact our customer support team. By using
                                                            Temp, you agree to our use of geofencing
                                                            technology as described in these terms and
                                                            conditions."</p>

                                                        <!--/Section 18 Changes to this agreement</-->

                                                        <p><span style="font-size: 14px;"><strong>18.
                                                                    Changes to this
                                                                    agreement</strong></span></p>
                                                        <p>Temp reserves the right to modify the terms
                                                            of this Agreement or its policies relating
                                                            to the Temp platform at any time, effective
                                                            upon the posting of an updated version of
                                                            this Agreement on the Temp website.</p>
                                                        <p>By continuing to use the Temp platform or
                                                            receiving Services from Temp (including
                                                            continuing with an existing or outstanding
                                                            Job), the Job provider and worker confirm
                                                            they agree to be bound by this Agreement and
                                                            any amended terms of this Agreement.</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mb-2">
                                                <label for="opted"
                                                    class="customcheckbox-container gr-check-input d-flex font-size-3 line-height-reset pl-8 mx-auto mt-1">
                                                    Sign up for marketing promotions and offers<span style="
                                      color: white;
                                  ">qqqqqq</span>
                                                    <input type="checkbox" id="opted" name="opted"
                                                        formControlName="optedin"
                                                        (change)="fieldsChange($event)" checked>
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label for="staffTerms"
                                                    class="customcheckbox-container gr-check-input d-flex font-size-3 line-height-reset pl-8 mx-auto mt-1 mb-0">
                                                    I
                                                    agree and have read the Terms & Conditions above
                                                    <input type="checkbox" id="staffTerms" name="terms-check2"
                                                        formControlName="agreed">
                                                    <span class="checkmark"></span>
                                                </label>
                                                <div class="text-danger small mt-1"
                                                    *ngIf="(staffDirective2.submitted)   && work3form.get('agreed').invalid && work3form.get('agreed').errors">
                                                    Field is required
                                                </div>
                                            </div>
                                            <div class="form-group mb-md-8 row m-0 mt-md-4 mt-4">
                                                <div class="col-6 px-0 pr-2">
                                                    <button type="button"
                                                        class="btn btn-outline-primary btn-medium w-100 rounded-5 text-capitalize signHeight"
                                                        (click)="wIndex=2">Back</button>
                                                </div>
                                                <div class="col-6 px-0 pl-2">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Sign
                                                        up</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" [class.show]="activeTab === 'staff'"
                                [class.active]="activeTab === 'staff'" id="pillsstaff" role="tabpanel"
                                aria-labelledby="pillsstaff">
                                <div class="stepper md-2">
                                    <ul class="nav nav-tabs border-0 d-flex justify-content-center"
                                        role="tablist" style="top: 15px;">
                                        <li style="margin-left: 10%;"
                                            [ngClass]="{'active': (sIndex == 1), 'completed':(sIndex > 1)}">
                                            <a class="persistant-disabled" aria-controls="stepper-step-1"
                                                role="tab" title="Step 1">
                                                <span class="round-tab">1</span>
                                            </a>
                                        </li>
                                        <li
                                            [ngClass]="{'active': (sIndex == 2), 'completed':(sIndex > 2), 'disabled': (sIndex < 2) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-2"
                                                role="tab" title="Step 2">
                                                <span class="round-tab">2</span>
                                            </a>
                                        </li>
                                        <li
                                            [ngClass]="{'active': (sIndex == 3), 'completed':(sIndex > 3), 'disabled': (sIndex < 3) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-3"
                                                role="tab" title="Step 3">
                                                <span class="round-tab">3</span>
                                            </a>
                                        </li>
                                        <li [ngClass]="{'active': (sIndex == 4), 'disabled': (sIndex != 4) }">
                                            <a class="persistant-disabled" aria-controls="stepper-step-4"
                                                role="tab" title="Step 4">
                                                <span class="round-tab">4</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="row no-gutters margin-top-3">
                                    <div class="staff-step-1 col-12" *ngIf="sIndex == 1 ">
                                        <form [formGroup]="staff1form" #formDirective="ngForm"
                                            (ngSubmit)="!staff1form.invalid && checkEmail()">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="First Name"
                                                    name="firstName" formControlName="firstName">
                                                <ng-container
                                                    *ngIf="(formDirective.submitted)  && staff1form.get('firstName').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff1form.get('firstName').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('firstName').errors.required && staff1form.get('firstName').errors.maxlength">
                                                        Maximum 20 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Last Name"
                                                    name="lastName" formControlName="lastName">
                                                <ng-container
                                                    *ngIf="(formDirective.submitted)  && staff1form.get('lastName').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff1form.get('lastName').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('lastName').errors.required && staff1form.get('lastName').errors.maxlength">
                                                        Maximum 20 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="tel" class="form-control"
                                                    placeholder="Phone Number" name="phoneNumber"
                                                    formControlName="phoneNumber">
                                                <ng-container
                                                    *ngIf="(formDirective.submitted)  && staff1form.get('phoneNumber').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff1form.get('phoneNumber').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('phoneNumber').errors.required && 
                                          !staff1form.get('phoneNumber').errors.pattern && staff1form.get('phoneNumber').errors.maxlength">
                                                        Maximum 12 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('phoneNumber').errors.required && staff1form.get('phoneNumber').errors.pattern">
                                                        Invalid phone number
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fas fa-info-circle"
                                                        tooltip="john@email.com" placement="left"></i>
                                                    <input type="text" class="form-control pr-9"
                                                        placeholder="Email" formControlName="email">
                                                    <ng-container
                                                        *ngIf="(formDirective.submitted)  && staff1form.get('email').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff1form.get('email').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff1form.get('email').errors.required && staff1form.get('email').errors.pattern">
                                                            Invaild email address
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff1form.get('email').errors.required && !staff1form.get('email').errors.pattern && staff1form.get('email').errors.maxlength">
                                                            Maximum 30 Characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff1form.get('email').errors.required && !staff1form.get('email').errors.pattern && staff1form.get('email').errors.minlength">
                                                            Minimum 6 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 1" name="address1"
                                                    formControlName="address1">
                                                <ng-container
                                                    *ngIf="(formDirective.submitted) && staff1form.get('address1').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff1form.get('address1').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('address1').errors.required && staff1form.get('address1').errors.maxlength">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 2" name="address2"
                                                    formControlName="address2">
                                                <ng-container
                                                    *ngIf="(formDirective.submitted)  && staff1form.get('address2').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff1form.get('address2').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff1form.get('address2').errors.required && staff1form.get('address2').errors.maxlength">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="row col-12 no-gutters px-0">
                                                <div class="form-group col-6 pr-1">
                                                    <input type="text" class="form-control" placeholder="City"
                                                        name="city" formControlName="city">
                                                    <ng-container
                                                        *ngIf="(formDirective.submitted)  && staff1form.get('city').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff1form.get('city').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff1form.get('city').errors.required && staff1form.get('city').errors.maxlength">
                                                            Maximum 30 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div class="form-group post-code-group col-6 pl-1">
                                                    <input type="text" class="form-control"
                                                        placeholder="Post Code" name="postCode"
                                                        formControlName="postCode" required>
                                                    <ng-container
                                                        *ngIf="(formDirective.submitted)  && staff1form.get('postCode').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff1form.get('postCode').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger pl-1"
                                                            *ngIf="!staff1form.get('postCode').errors.required && !staff1form.get('postCode').errors.pattern && (staff1form.get('postCode').errors.minlength || staff1form.get('postCode').errors.maxlength)">
                                                            Invaild post code
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff1form.get('postCode').errors.required && staff1form.get('postCode').errors.pattern">
                                                            Invaild post code
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group mb-5 mt-md-7 m-0">
                                                <button type="submit"
                                                    class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                            </div>
                                            <p class="font-size-4 text-center heading-default-color" (click)="navigateLogin()">Already have an account?<span
                                                role="button" class="text-primary">&nbsp;Sign in</span></p>
                                        </form>
                                    </div>
                                    <div class="staff-step-2 col-12" *ngIf="sIndex == 2">
                                        <form [formGroup]="staff2form" #formDirective1="ngForm"
                                            (ngSubmit)="!staff2form.invalid && sIndex=3">
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fa"
                                                        (click)="togglePassword()"
                                                        [ngClass]="{'fa-eye-slash ': !isPasswordVisible, 'fa-eye':isPasswordVisible}"></i>
                                                    <input [type]="isPasswordVisible?'text':'password'"
                                                        autocomplete="on" class="form-control pr-9"
                                                        placeholder="Password" name="password"
                                                        formControlName="password">
                                                    <ng-container
                                                        *ngIf="(formDirective1.submitted)  && staff2form.get('password').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff2form.get('password').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff2form.get('password').errors.required && staff2form.get('password').errors.minlength">
                                                            Minimum 8 characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff2form.get('password').errors.required && staff2form.get('password').errors.maxlength">
                                                            Maximum 50 characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <div class="position-relative">
                                                    <i class="position-absolute password-eye fa"
                                                        (click)="toggleConfirmPassword()"
                                                        [ngClass]="{'fa-eye-slash ': !isConfirmPasswordVisible, 'fa-eye':isConfirmPasswordVisible}"></i>
                                                    <input [type]="isConfirmPasswordVisible?'text':'password'"
                                                        autocomplete="on" class="form-control pr-9"
                                                        placeholder="Confirm Password"
                                                        formControlName="confirmPassword"
                                                        name="confirmPassword">
                                                    <ng-container
                                                        *ngIf="(formDirective1.submitted)  && staff2form.get('confirmPassword').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff2form.get('confirmPassword').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff2form.get('confirmPassword').errors.required && staff2form.get('confirmPassword').errors.NotEqual">
                                                            Password must match
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff2form.get('confirmPassword').errors.required && !staff2form.get('confirmPassword').errors.NotEqual && staff2form.get('confirmPassword').errors.minlength">
                                                            Minimum 8 characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff2form.get('confirmPassword').errors.required && !staff2form.get('confirmPassword').errors.NotEqual && staff2form.get('confirmPassword').errors.maxlength">
                                                            Maximum 50 characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Company Name" name="companyName"
                                                    formControlName="companyName">
                                                <ng-container
                                                    *ngIf="(formDirective1.submitted)  && staff2form.get('companyName').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff2form.get('companyName').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff2form.get('companyName').errors.required && staff2form.get('companyName').errors.minlength">
                                                        Minimum 2 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff2form.get('companyName').errors.required && staff2form.get('companyName').errors.maxlength">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <textarea class="form-control" placeholder="About your Company"
                                                    name="aboutCompany" formControlName="aboutCompany"
                                                    rows="7"></textarea>
                                                <ng-container
                                                    *ngIf="(formDirective1.submitted)  && staff2form.get('aboutCompany').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff2form.get('aboutCompany').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff2form.get('aboutCompany').errors.required && staff2form.get('aboutCompany').errors.minlength">
                                                        Minimum 20 Characters
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff2form.get('aboutCompany').errors.required && staff2form.get('aboutCompany').errors.maxlength">
                                                        Maximum 500 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group mb-md-8 row m-0 mt-md-14 mt-10">
                                                <div class="col-6 px-0 pr-2">
                                                    <button type="button"
                                                        class="btn btn-outline-primary btn-medium w-100 rounded-5 text-capitalize signHeight"
                                                        (click)="sIndex=1">Back</button>
                                                </div>
                                                <div class="col-6 px-0 pl-2">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="staff-step-3 col-12" *ngIf="sIndex == 3">
                                        <form [formGroup]="staff3form" #formDirective2="ngForm"
                                            (ngSubmit)="!staff3form.invalid && getCasualElement()">
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Billing First Name" name="billingFirstName"
                                                    formControlName="billingFirstName">
                                                <ng-container
                                                    *ngIf="(formDirective2.submitted)  && staff3form.get('billingFirstName').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff3form.get('billingFirstName').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff3form.get('billingFirstName').errors.required && staff3form.get('billingFirstName').errors.maxlength">
                                                        Maximum 20 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Billing Last Name" name="billingLastName"
                                                    formControlName="billingLastName">
                                                <ng-container
                                                    *ngIf="(formDirective2.submitted)  && staff3form.get('billingLastName').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff3form.get('billingLastName').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff3form.get('billingLastName').errors.required && staff3form.get('billingLastName').errors.maxlength">
                                                        Maximum 20 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 1" name="billingAddress1"
                                                    formControlName="billingAddress1">
                                                <ng-container
                                                    *ngIf="(formDirective2.submitted)  && staff3form.get('billingAddress1').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff3form.get('billingAddress1').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff3form.get('billingAddress1').errors.required && staff3form.get('billingAddress1').errors.maxlength">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="Address Line 2" name="billingAddress2"
                                                    formControlName="billingAddress2">
                                                <ng-container
                                                    *ngIf="(formDirective2.submitted)  && staff3form.get('billingAddress2').invalid">
                                                    <small class="text-danger"
                                                        *ngIf="staff3form.get('billingAddress2').errors.required">
                                                        Field is required
                                                    </small>
                                                    <small class="text-danger"
                                                        *ngIf="!staff3form.get('billingAddress2').errors.required && staff3form.get('billingAddress2').errors.maxlength">
                                                        Maximum 50 Characters
                                                    </small>
                                                </ng-container>
                                            </div>
                                            <div class="row col-12 no-gutters px-0">
                                                <div class="form-group col-12 col-md-6 pr-md-1">
                                                    <input type="text" class="form-control" placeholder="City"
                                                        name="city" formControlName="city">
                                                    <ng-container
                                                        *ngIf="(formDirective2.submitted)  && staff3form.get('city').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff3form.get('city').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff3form.get('city').errors.required && staff3form.get('city').errors.maxlength">
                                                            Maximum 30 Characters
                                                        </small>
                                                    </ng-container>
                                                </div>
                                                <div class="form-group col-12 col-md-6 pl-md-1">
                                                    <input type="text" class="form-control"
                                                        placeholder="Post Code" name="postCode"
                                                        formControlName="postCode" required>
                                                    <ng-container
                                                        *ngIf="(formDirective2.submitted)  && staff3form.get('postCode').invalid">
                                                        <small class="text-danger"
                                                            *ngIf="staff3form.get('postCode').errors.required">
                                                            Field is required
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff3form.get('postCode').errors.required && !staff3form.get('postCode').errors.pattern &&(staff3form.get('postCode').errors.maxlength || staff3form.get('postCode').errors.minlength)">
                                                            Minimum/Maximum 4 characters
                                                        </small>
                                                        <small class="text-danger"
                                                            *ngIf="!staff3form.get('postCode').errors.required && staff3form.get('postCode').errors.pattern">
                                                            Invalid post code
                                                        </small>
                                                    </ng-container>
                                                </div>
                                            </div>
                                            <div class="form-group mb-md-8 row m-0 mt-md-30 mt-17">
                                                <div class="col-6 px-0 pr-2">
                                                    <button type="button"
                                                        class="btn btn-outline-primary btn-medium w-100 rounded-5 text-capitalize signHeight"
                                                        (click)="sIndex=2">Back</button>
                                                </div>
                                                <div class="col-6 px-0 pl-2">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                    <div class="staff-step-4 col-12" [hidden]="!(sIndex == 4)">
                                        <form [formGroup]="staff4form" #formDirective3="ngForm"
                                            class="fouthForm"
                                            (ngSubmit)="!staff4form.invalid && cardDetailsEntered()">
                                            <div>
                                                <h5 class="font-weight-600">Choose your Payment Method
                                                </h5>
                                                <div>Pay by card or pay by invoice. Payment upon invoice
                                                    is due within 3 days of issue.</div>
                                                <div class="mt-4 payment-method">
                                                    <select class="selectpicker form-control"
                                                        formControlName="paymentMethod" title="paymentMethod"
                                                        (change)="paymentMethodChanged($event)">
                                                        <option value="card">Card</option>
                                                        <option value="invoice">Invoice</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <!-- <form [formGroup]="staff6form" #formDirective5="ngForm"> -->
                                            <div *ngIf="paymentMethod == 'card'" class="mt-2">
                                                <div class="mb-3 mt-1">
                                                    <div class="row justify-content-center align-items-center mt-4"
                                                        *ngIf="showMonthYearToggle">
                                                        <label class="mb-0 font-weight-semibold pr-6"
                                                            for="customSwitch2" role="button"
                                                            [ngClass]="{'text-black': !isYearly}">Monthly</label>
                                                        <div class="custom-control custom-switch">
                                                            <input type="checkbox" class="custom-control-input"
                                                                id="customSwitch2" [checked]="isYearly"
                                                                role="button" (change)="toggleChange($event)"
                                                                name="isYearly" formControlName="isYearly">
                                                            <label
                                                                class="custom-control-label font-weight-semibold pl-6"
                                                                role="button" for="customSwitch2"
                                                                [ngClass]="{'text-black': isYearly}">Annual<br></label>
                                                        </div>
                                                    </div>
                                                    <div class="mt-8">
                                                        <div *ngFor="let plan of plansArr">
                                                            <div class="card mt-3" *ngIf="!plan.deleted"
                                                                [ngClass]="{'temp-text-border': staff4form.get('planSelected').value == plan.id}"
                                                                (click)="getPaymentElement(plan.id)">
                                                                <div class="row g-0">
                                                                    <div class="col-2 pr-0">
                                                                        <div
                                                                            class="d-flex justify-content-center align-items-center h-100">
                                                                            <div
                                                                                [ngClass]="{'upload-symbols':staff4form.get('planSelected').value == plan.id,'normal-symbols':staff4form.get('planSelected').value != plan.id}">
                                                                                <i class="fas "
                                                                                    [ngClass]="{'fa-check':staff4form.get('planSelected').value == plan.id,'fa-circle':staff4form.get('planSelected').value != plan.id}"></i>
                                                                            </div>
                                                                            <input class="" [value]="plan.id"
                                                                                type="radio" name="planSelected"
                                                                                formControlName="planSelected"
                                                                                required [hidden]="true">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-7 pl-0">
                                                                        <div class="card-body p-0 py-2">
                                                                            <div class="m-0"><span
                                                                                    class="plan-name font-segoe-ui">{{plan.planName}}</span>
                                                                                {{plan.monthlyPrice == 0
                                                                                ? '' : ('('+(isYearly ?
                                                                                ('$'+plan.annualPrice+'/year)'):(plan.monthlyPrice+'/Month)')))}}
                                                                            </div>
                                                                            <div class="m-0">
                                                                                <span
                                                                                    class="plan-subtext font-segoe-ui">
                                                                                    {{plan.monthlyPrice == 0 ?
                                                                                    'No monthly cost':
                                                                                    (plan.feePercentage+'%Fee')}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="isYearly && plan.savePercentage != 0"
                                                                        class="col-3">
                                                                        <div
                                                                            class="d-flex justify-content-center align-items-center h-100 text-success font-weight-bold font-segoe-ui">
                                                                            <small>Save {{isYearly ?
                                                                                ('$'+plan.monthlyPrice*12*(plan.savePercentage/100)):(plan.savePercentage+'%')}}</small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <ng-container
                                                            *ngIf="(formDirective3.submitted)  && staff4form.get('planSelected').invalid">
                                                            <small class="text-danger mt-1 font-segoe-ui"
                                                                *ngIf="staff4form.get('planSelected').errors.required">
                                                                Please select a plan
                                                            </small>
                                                        </ng-container>
                                                    </div>
                                                </div>
                                                <div id="payment-element">
                                                    <!-- Elements will create form elements here -->
                                                </div>
                                            </div>
                                            <div *ngIf="paymentMethod != 'card'">
                                                <div class="form-group col-12 mt-7 p-0">
                                                    <input type="text" class="form-control"
                                                        placeholder="Company Code" name="companyCode"
                                                        formControlName="companyCode" required>
                                                    <small class="text-danger"
                                                        *ngIf="companyCodeInvalid">Company code is
                                                        invalid</small>
                                                </div>
                                                <div class="mt-2">
                                                    What's this? Talk to our <a href="mailto:sales@temp.org.nz"
                                                        class="text-decoration-underline">Support
                                                        team</a> and we will provide you one.
                                                </div>
                                                <div class="mt-3">
                                                    <div class="card temp-text-border">
                                                        <div class="row g-0">
                                                            <div class="col-2 pr-0">
                                                                <div
                                                                    class="d-flex justify-content-center align-items-center h-100">
                                                                    <div class="upload-symbols">
                                                                        <i class="fas fa-check"></i>
                                                                    </div>
                                                                    <input class="" value="5" type="radio"
                                                                        name="planSelected"
                                                                        formControlName="planSelected" required
                                                                        [hidden]="true">
                                                                </div>
                                                            </div>
                                                            <div class="col-7 pl-0">
                                                                <div class="card-body p-0 py-2">
                                                                    <div class="m-0"><span
                                                                            class="plan-name font-segoe-ui">Enterprise
                                                                            - Pay On Invoice</span>
                                                                    </div>
                                                                    <div class="m-0"><span
                                                                            class="plan-subtext font-segoe-ui">20%
                                                                            Service fee on hires</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row m-0 mobileMargin"
                                                [ngClass]="{'mt-10': paymentMethod == 'card', 'mt-25': paymentMethod != 'card'}">
                                                <div class="col-6 px-0 pr-2 mt-10">
                                                    <button type="button"
                                                        class="btn btn-outline-primary btn-medium w-100 rounded-5 text-capitalize signHeight"
                                                        (click)="sIndex=3">Back</button>
                                                </div>
                                                <div class="col-6 px-0 pl-2 mt-10">
                                                    <button type="submit"
                                                        class="btn btn-primary btn-medium w-100 rounded-5 text-capitalize signHeight">Next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="staff-terms col-12">
                    <div class="staff-terms-div bg-white-2 h-100 px-md-11 px-6 pt-8 pb-7" *ngIf="sIndex == 5">
                        <form [formGroup]="staff5form" #formDirective4="ngForm"
                            (ngSubmit)="staff5form.valid && paymentSuccessLink()">
                            <div class="form-group">
                                <div class="terms-div border overflow-auto">
                                    <div class="terms-heading font-weight-bold pt-2">Terms & Conditions
                                    </div>
                                    <div class="pt-2">AGREEMENT TO TERMS</div><br />
                                    <div class="text-justify pl-6 pl-md-0 pr-5 pr-md-0 termsAndCondition"
                                        style="font-size: 14px;">
                                        <p style="font-size: 14px;">These Terms and Conditions
                                            constitute a legally binding agreement made between you,
                                            whether personally or on behalf of an entity
                                            (&ldquo;you&rdquo;) and Temp (&ldquo;we,&rdquo;
                                            &ldquo;us&rdquo; or &ldquo;our&rdquo;), concerning your
                                            access to and use of the Temp platform as well as any other
                                            media form, media channel, mobile website or mobile
                                            application related, linked, or otherwise connected thereto
                                            (collectively, the &ldquo;Site&rdquo;).</p>

                                        <!--/Section 0 Preamble/-->
                                        <h2 class="text-justify pl-6 pl-md-0 pr-5 pr-md-0"
                                            style="font-size: 14px;"><strong>Preamble</strong></h2>
                                        <ul style="font-size: 14px;padding-left: 0px;">
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">Temp
                                                    provides an online platform to facilitate Temporary
                                                    jobs.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">The Job
                                                    provider/business wishes to engage Temp to find
                                                    workers and schedule jobs as outlined in a job
                                                    brief.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">This
                                                    agreement sets out the terms between the 3 parties
                                                    involved. Temp, the Worker, and the Job provider</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">By
                                                    agreeing to these terms, the parties confirm that
                                                    they are bound by this Agreement and any amended
                                                    term of this Agreement that is subsequently changed
                                                    in accordance with the terms of this Agreement.</p>
                                            </li>
                                        </ul>

                                        <p><span style="font-size: 14px;"><strong>1. Definition and
                                                    interpretations as defined in this
                                                    agreement.</strong></span></p>
                                        <p><span style="font-size: 14px;"><strong>1.1
                                                    Definition</strong></span></p>

                                        <p style="font-size: 14px;"><strong>Agreement:</strong> Means
                                            this agreement including any document signed by the Parties
                                            that varies or supplements it in accordance with its terms.
                                        </p>
                                        <p style="font-size: 14px;"><strong>Worker: </strong>Means a
                                            person engaged to provide services operating as an
                                            independent contractor by a Job provider on the Temp
                                            platform. This does not include workers of Temp Limited.</p>
                                        <p style="font-size: 14px;"><strong>Job provider:</strong> Means
                                            you are operating as a Job provider looking to engage a
                                            worker for temporary work services.&nbsp;</p>
                                        <p style="font-size: 14px;"><strong>Business: </strong>Means the
                                            job provider who is looking to hire using the Temp platform
                                        </p>
                                        <p style="font-size: 14px;"><strong>Temporary:</strong> Refers
                                            to the job duration and the nature of the work completed
                                            through the Temp platform. This can be an ongoing engagement
                                            between the worker and the Job provider that has no end
                                            date, providing both parties agree to the terms.&nbsp;&nbsp;
                                        </p>
                                        <p style="font-size: 14px;"><strong>Job:</strong> Is what is
                                            created by the Job provider and completed by the
                                            worker.&nbsp;</p>
                                        <p style="font-size: 14px;"><strong>Temp platform:</strong>
                                            Means any system, website or platform provided by Temp
                                            (including the Temp Site) to facilitate the engagement
                                            between workers and Job providers.</p>
                                        <p style="font-size: 14px;"><strong>Temp: </strong>Means Temp
                                            Limited and is referred to in this Agreement as Temp or the
                                            company</p>
                                        <p style="font-size: 14px;"><strong>Job Brief/Job
                                                description:</strong> This outlines the details in terms
                                            of what is required for the worker to undertake the job.</p>
                                        <p style="font-size: 14px;"><strong>Hourly rate:
                                            </strong>Defines the remuneration for each hour of work
                                            completed.&nbsp;</p>
                                        <p style="font-size: 14px;"><strong>Work:</strong> Means
                                            services performed or provided by a worker at the work site
                                            in response to a Job Brief.</p>
                                        <p style="font-size: 14px;"><strong>Service fee:</strong>
                                            Meaning Temp&rsquo;s percentage calculated based on the job
                                            brief. This may change based on the Job providers
                                            subscription plan.</p>
                                        <p style="font-size: 14px;"><strong>Work site: </strong>Means
                                            any location, premises or building at which a worker is,
                                            will or has worked for the purposes of providing the Work.
                                        </p>
                                        <p style="font-size: 14px;"><strong>Representative:</strong>
                                            Means any director, officer, employee, agent, contractor, or
                                            other person representing the Job provider.</p>
                                        <p style="font-size: 14px;"><strong>Licenses and
                                                qualifications:</strong>&nbsp;Temp does not verify or
                                            investigate the authenticity of any prerequisites added
                                            under the Licenses and qualification section. This feature
                                            is simply an advertising feature and by no means a reliable
                                            method of validation. It is up to the Job provider to decide
                                            the authenticity of the workers licenses or qualifications.
                                        </p>


                                        <p><span style="font-size: 14px;"><strong>1.2
                                                    Interpretations</strong></span></p>

                                        <p style="font-size: 14px;">In this Agreement, except where the
                                            context otherwise requires:&nbsp;</p>
                                        <ol type="A" style="padding-left:0px">
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">words in
                                                    the singular form also include their plural form,
                                                    and vice versa, and words denoting a gender also
                                                    include other genders.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">
                                                    alternative grammatical forms of defined terms have
                                                    corresponding meanings.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    reference to money is in New Zealand currency.&nbsp;
                                                </p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    reference to time is in accordance with New Zealand
                                                    Daylight Time or New Zealand Standard Time (as
                                                    applicable).</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    reference to a Party includes their executors,
                                                    administrators, successors, and permitted assigns
                                                    and substitutes.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    reference to a person includes individuals,
                                                    partnerships, corporations, associations, government
                                                    entities, or other legal entities.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    reference to a statute, ordinance, code, or other
                                                    law includes regulations and other instruments made
                                                    under it, and any consolidation, amendment,
                                                    re-enactment, or replacement of them.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">the
                                                    meaning of general words is not limited by specific
                                                    examples provided with phrases like "including",
                                                    "for example", or similar expressions.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">headings
                                                    are for convenience only and do not affect the
                                                    interpretation of the agreement.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">if a
                                                    Party comprises of multiple persons, the agreement
                                                    binds each of them separately and any two or more of
                                                    them jointly.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    obligation, representation, or warranty made in
                                                    favour of multiple persons is for their joint and
                                                    collective benefit.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">any
                                                    agreement, representation, warranty or indemnity
                                                    made in favour of two or more Parties (including
                                                    where two or more persons are included in the same
                                                    defined term) is for their joint and several
                                                    benefit.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">no rule
                                                    of construction shall be interpreted to the
                                                    disadvantage of a Party merely because they were
                                                    responsible for preparing this Agreement or any part
                                                    of it.</p>
                                            </li>
                                        </ol>
                                        <!--/Section 2 General/-->
                                        <p><span style="font-size: 14px;"><strong>2.1. User
                                                    Accounts</strong></span><br>To use the Temp
                                            platform, you must register and maintain an active user
                                            account with Temp (in its capacity as licensor of the Temp
                                            branded application and websites) (&ldquo;Account&rdquo;)
                                            (as described in section 3 (&lsquo;Contractual relationship)
                                            of the Temp Terms). Your failure to maintain accurate,
                                            complete, and up-to-date Account information, including
                                            having an invalid or expired payment method on file, may
                                            result in your inability to access or use the Temp platform.
                                            If information provided is found to be misleading or false,
                                            Temp may immediately terminate (as described in clause 13)
                                            the account and any future involvement with the party at
                                            Temps sole discretion.</p>


                                        <!--/Section 3 Contractual relationship/-->

                                        <p><span style="font-size: 14px;"><strong>3. Contractual
                                                    relationship</strong></span><br><span
                                                style="font-size: 14px;"><strong>3.1. Temp
                                                    services</strong></span><br>Temp will provide
                                            services in accordance with these terms and conditions. Temp
                                            Services constitute the provision of a technology platform
                                            that enables you, as a user of Temp&rsquo;s applications or
                                            websites (each, an &ldquo;Application&rdquo;) to: (a)
                                            arrange and schedule certain jobs with independent
                                            third-party providers of those services that have an
                                            agreement with Temp or its affiliates (&ldquo;Third Party
                                            Providers&rdquo;), including: (i) Job providers providing
                                            the job opportunity and workers who are providing the work
                                            services.</p>
                                        <p><span style="font-size: 14px;"><strong>3.2. Obligations as a
                                                    worker</strong></span><br>By applying for a job, the
                                            worker accepts that they are entering into an agreement with
                                            the Business (the job provider) to uphold the terms and
                                            conditions outlined in this agreement.</p>
                                        <p><strong>3.2.1. </strong>As a worker on the Temp platform, you
                                            acknowledge that when you apply for a job it is at the sole
                                            discretion of the Job provider whether to hire you or not.
                                            Once you have applied for a job you accept that you have
                                            committed to the dates set forth in the job description.
                                            Failure to turn up to an already accepted job without valid
                                            reason may result in a ban from the Temp platform. This is
                                            at the sole discretion of Temp which will carry out an
                                            investigation after the job period has ended. Temp will only
                                            investigate issues that have been reported. Workers must
                                            ensure they are of legal age required to complete the job.
                                            This includes being at least 18 years or older when working
                                            with Alcohol and at least 21 years of age when working on a
                                            premises with gambling facilities.</p>
                                        <p><strong>3.2.2.</strong> As a worker you operate as an
                                            independent contractor which means you have no guarantee of
                                            work, and the business (the job provider) is not obligated
                                            to handle any Tax, ACC, or employment benefit on your
                                            behalf. You are obligated by law to pay tax on all your
                                            earnings that you have made by using the Temp platform. Temp
                                            does not deduct tax from workers earnings. Workers are to
                                            make their own arrangements to pay tax on earnings made
                                            through the Temp platform.</p>
                                        <p><strong>3.2.3. </strong>Workers are not employees of Temp nor
                                            are they paid by Temp. Temp acts as a digital software
                                            provider that connects Job providers and workers.</p>
                                        <p><strong>3.2.4.</strong> Workers agree that they must complete
                                            the job to the best of their ability.</p>
                                        <p><span style="font-size: 14px;"><strong>3.3. Obligations as a
                                                    Job provider</strong></span><br>By posting a job the
                                            Job provider agrees that they are entering into an agreement
                                            with the worker that is set forth in this agreement.</p>
                                        <p><strong>The Job provider must:</strong></p>
                                        <p><strong>3.3.1.</strong> provide safe and suitable working
                                            conditions that are in accordance with the Health and Safety
                                            at Work Act 2015.</p>
                                        <p><strong>3.3.2.</strong> provide Temp with full and accurate
                                            information about the service requirements relevant to the
                                            Job Brief, and all other information and assistance
                                            reasonably necessary to enable Temp to provide services and
                                            workers to perform their work; This should include the
                                            nature of the task in which the Job provider is hiring to
                                            complete and any safety equipment that might be used/needed.
                                        </p>
                                        <p><strong>3.3.3. </strong>not allocate tasks or
                                            responsibilities to the workers or require the workers to
                                            perform or participate in work other than in accordance with
                                            the relevant Job description.</p>
                                        <p><strong>3.3.4. </strong>Immediately advise Temp in writing
                                            once it becomes aware that the work, role, responsibilities,
                                            or work conditions the Job provider sets for a worker at the
                                            time when the work is being performed do not match the role
                                            classification that the Job provider selected when creating
                                            the Job Brief;</p>
                                        <p><strong>3.3.5. </strong>Ensure that workers will be covered
                                            by the following insurance policies, or other suitable and
                                            permissible statutory indemnity or self-insurance
                                            arrangements, whilst performing their Work at the Work Site:
                                        </p>
                                        <p><strong>3.3.5.1.</strong> public liability, professional
                                            indemnity, if relevant to the type of Work to be performed.
                                        </p>
                                        <p><br><strong>3.3.5.2. </strong>compulsory third party motor
                                            vehicle insurance, and fully comprehensive motor vehicle
                                            insurance (including third party personal injury, own
                                            damage, third party property damage liability and third
                                            party personal injury gap) for any vehicles operated by a
                                            worker;</p>
                                        <p>Failure to meet these requirements or misrepresentation of
                                            the skill level or workload of the job may result in a ban
                                            from the Temp platform.</p>

                                        <!--/Section 4 Ratings/-->

                                        <p><span style="font-size: 14px;"><strong>4.
                                                    Ratings</strong></span><br>By entering a job as the
                                            job provider or as the worker you acknowledge that you will
                                            be rated at the end of the job period. Quality of ratings is
                                            of the opinion of each party and should comply with the
                                            terms outlined in this agreement. It is important to know
                                            that it is not Temps obligation to censor ratings, Temp may
                                            choose to intervene at its sole discretion if job feedback
                                            includes inappropriate language or unreasonable criticism.
                                        </p>


                                        <p><span style="font-size: 14px;"><strong>4.1. Ratings for Job
                                                    providers</strong></span><br><strong>4.1.1 Rating
                                                your worker</strong><br>All ratings should be an
                                            accurate representation of the workers efforts and
                                            commitment to the job.</p>
                                        <p><strong>4.1.2. Receiving a rating as a Job
                                                provider</strong><br>As a Job provider you accept that
                                            by hiring through the Temp platform you acknowledge you will
                                            be rated on your performance as a Job provider. Ratings are
                                            completely the opinion of the workers you hire and what
                                            keeps the platform fair. There is a possibility for you to
                                            receive an unsatisfactory rating from a worker and it is not
                                            the job of Temp to influence, edit or change this unless
                                            Temp considers that the rating was unfairly or dishonestly
                                            provided.</p>

                                        <p><span style="font-size: 14px;"><strong>4.2. Ratings for
                                                    workers</strong></span><br><strong>4.2.1.Rating your
                                                Job provider</strong><br>All ratings should be an
                                            accurate representation of the Job providers efforts to
                                            create a fair and suitable work environment.</p>
                                        <p><strong>4.2.2. Receiving a rating as a worker</strong><br>As
                                            a worker you acknowledge that you will be rated on your
                                            performance for each job you complete through the Temp
                                            platform. While ratings are meant to be an accurate
                                            representation of your effort and commitment. There is a
                                            possibility for you to receive an unsatisfactory rating. It
                                            is not the job of Temp to influence, edit or change this
                                            unless Temp considers that it was unfairly or dishonestly
                                            provided.</p>

                                        <!--/Section 5 Disputes/-->

                                        <p><span style="font-size: 14px;"><strong>5.
                                                    Disputes</strong></span><br>Any disputes over job
                                            quality, pay or other contexts are to be handled between the
                                            business (Job provider) and the worker (the services
                                            provider). Temp acts as the digital facilitator to connect
                                            both parties for work opportunities and does not have any
                                            involvement nor is obligated to participate in any dispute
                                            resolution.&nbsp;</p>
                                        <p>Where there is a dispute between the Job provider and the
                                            worker, funds that have already been paid to Temp will be
                                            refunded to the Job provider.</p>

                                        <!--/Section 6. Cancellation of Job Brief by Job provide/-->

                                        <p><span style="font-size: 14px;"><strong>6. Cancellation of Job
                                                    Brief by Job provider</strong></span><br>The Job
                                            Provider may (i) cancel a Job Brief or any part of it, or
                                            (ii) make any amendment to the Job Brief which causes the
                                            shift of one or more worker(s) to be canceled, without any
                                            liability at any time, provided that such cancellation or
                                            amendment is done using the Temp Platform, 4 hours prior to
                                            the expected commencement time of the affected shift(s) by a
                                            worker(s) under the Job Brief (&ldquo;Acceptable
                                            Cancellation Period&rdquo;).</p>

                                        <!--/Section 7 Payments/-->

                                        <p><span style="font-size: 14px;"><strong>7.
                                                    Payments</strong></span><br><span
                                                style="font-size: 14px;"><strong>7.1. Hourly
                                                    Rates</strong></span><br>unless there is a written
                                            agreement between Temp and the job provider. The Job
                                            provider is obligated to pay Temp for each Job description
                                            an amount equal to the sum of the components specified in
                                            sections 7.1.1 to 7.1.5.</p>
                                        <p><strong>7.1.1. </strong>The total gross payment to be paid to
                                            the worker(s) also known at the Worker(s) payment.</p>
                                        <p><strong>7.1.2.&nbsp;</strong>The Service Fee will be
                                            calculated by multiplying the sum of all components from
                                            Clause 7.1.1 by the Job providers prescribed rate, unless
                                            otherwise agreed in writing by Temp.</p>
                                        <p><strong>7.1.3.&nbsp;</strong>(the total sum payable by the
                                            Job provider under a Job description as set out in 7.1.1 to
                                            7.1.2 above is collectively known as the
                                            &ldquo;Rate&rdquo;).</p>
                                        <p><strong>7.1.4.&nbsp;</strong>The Job provider will be shown
                                            an estimated rate applicable to the Job description after
                                            they have filled out the relevant information of a Job
                                            description (but prior to posting the Job via the Temp
                                            Platform). The Job provider may request further information
                                            from Temp regarding the estimated Rate (including its
                                            breakdown or calculation). The Job provider accepts that the
                                            estimated Rate is an approximation based on the information
                                            provided by the Job provider at the time when the Job
                                            description is filled out and/ or posted. The actual Rate
                                            payable by the Job provider will be confirmed after the
                                            Services and Work by the worker(s) are performed, and/ or
                                            after the timesheet is submitted and verified pursuant to
                                            Clause 7.2.</p>

                                        <p><span style="font-size: 14px;"><strong>7.2. Variation of
                                                    rate</strong></span></p>
                                        <p><strong>7.2.1. </strong>The Job provider acknowledges that
                                            the total workers payment and rate components referred to in
                                            Clause 7.1 are subject to change from time to time as a
                                            result of a variation of the estimated job duration. The Job
                                            provider agrees that such changes shall become effective and
                                            apply to the Rate from the date the changes are applied.
                                            Excluding any existing or outstanding Job Description that
                                            has already been accepted by both Parties, Temp will use its
                                            best endeavors to notify the Job provider of any such
                                            expected changes with reasonable notice. The Job provider
                                            agrees to pay Temp the increased Rate from the applicable
                                            effective date, including any proportional change to the
                                            amount of Service Fee as a result of such changes.</p>

                                        <p><span style="font-size: 14px;"><strong>7.3.
                                                    Subscriptions</strong></span><br>Unless otherwise
                                            agreed by written notice, the Job provider is responsible
                                            for paying Temp the subscription fee that corresponds with
                                            the subscription plan they have selected.</p>
                                        <p><strong>7.3.1. Subscriptions Fees and
                                                payment</strong><br>Immediately upon receipt of the
                                            invoice, the Job provider gives authorization to Temp to
                                            bill their credit card upon subscription for the Service and
                                            any subsequent renewals. Payment obligations may not be
                                            canceled within the 1 year subscription period. Subscription
                                            fees paid are non-refundable. The Job provider will pay the
                                            Fees using an accepted payment method as specified in the
                                            invoice or on Temp&rsquo;s website. Unless otherwise
                                            indicated, the Job Provider's subscription to the services
                                            will automatically renew for a Subscription Term in
                                            accordance with the renewal terms and conditions set forth
                                            in clause 7.3.5. During the Term of the Subscription Period,
                                            the Job Provider is not permitted to reduce their
                                            subscription plan.</p>
                                        <p><strong>7.3.2. Late Payments.</strong><br>If undisputed Fees
                                            are more than thirty (30) days overdue, then following
                                            written notification from Temp, Temp may suspend the Job
                                            provider&rsquo;s access to the Temp platform, including,
                                            without limitation, the Job provider&rsquo;s account, until
                                            such unpaid fees are paid in full.</p>
                                        <p><strong>7.3.3. Term, Termination and
                                                Suspension</strong><br>Subscriptions are effective at
                                            the date of enrollment in any subscription plan on the Temp
                                            website and will continue for a period of 1 year.</p>
                                        <p><strong>7.3.4. Subscription plans</strong><br>All plans are
                                            contracted for 1 year from the date of purchase. Job
                                            providers can choose to pay the yearly cost upfront to save
                                            10% percent off the total subscription fee. Temp may choose
                                            to additionally offer further discounts at its sole
                                            discretion.</p>
                                        <p style="font-size: 14px;"><strong>Available plans</strong></p>
                                        <ol>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">Basic
                                                    Plan is $50 monthly, plus a 10% service fee.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">Agency
                                                    Plan is $75 monthly, plus a 5% service fee</p>
                                            </li>
                                        </ol>
                                        <p style="font-size: 14px;">After the Job provider has entered
                                            into a subscription plan, they will not be eligible to
                                            switch to an annual subscription plan for any savings.</p>
                                        <p style="font-size: 14px;">A Basic Plan can be upgraded to an
                                            Agency plan at any time. Payment will be adjusted
                                            accordingly within the next billing cycle. If the Basic plan
                                            was prepaid annually then the Job provider will be charged
                                            for the remaining months within the current contract at the
                                            Agency annual rate. e.g., if the Job provider upgrades with
                                            6 months left of their Basic annual plan to an Agency annual
                                            plan, then they would be charged $135.</p>
                                        <p style="font-size: 14px;"><strong>7.3.5.
                                                Renewal.&nbsp;</strong><br>The Subscription Plans will
                                            automatically renew for a period equal to the previous
                                            Subscription Term unless a party provides written notice of
                                            non-renewal at least thirty (30) days prior to the
                                            expiration of the current term. At the beginning of each
                                            Subscription Term, Temp may increase the Fees, and any fees
                                            for a renewed term will be due on the date of renewal.</p>


                                        <p><span style="font-size: 14px;"><strong>7.4
                                                    Refunds</strong></span><br>Refunds will only be
                                            given for worker discrepancies or unfulfilled jobs and will
                                            not be given for subscriptions regardless of the usage.</p>
                                        <p><strong>7.4.1. Refund for worker
                                                discrepancies</strong><br>Refunds will be issued if the
                                            worker does not fulfill the total agreed hours. The Job
                                            provider will accurately record the workers hours in the
                                            Timesheet. (Clause 8)</p>
                                        <p><strong>7.4.2.&nbsp;</strong>Refunds will also be
                                            automatically sent if the job is not filled in the posting
                                            period. Refunds will be paid out within 10 days of the end
                                            of the posting period.</p>
                                        <p><span style="font-size: 14px;"><strong>7.5.
                                                    Payouts</strong></span><br>Payment processing
                                            services for Workers on Temp are provided by Stripe and are
                                            subject to the <span style="color: rgb(0, 176, 116);"><a
                                                    style="color: rgb(0, 176, 116);" target="_blank"
                                                    href="https://stripe.com/connect-account/legal/full">Stripe
                                                    Connected Account Agreement</a>,</span> which
                                            includes the <span style="color: rgb(0, 176, 116);"><a
                                                    style="color: rgb(0, 176, 116);" target="_blank"
                                                    href="https://stripe.com/legal">Stripe Terms of
                                                    Service </a> </span>(collectively, the &ldquo;Stripe
                                            Services Agreement&rdquo;). By agreeing to [this agreement /
                                            these terms / and so on] or continuing to operate as a
                                            Worker on Temp, you agree to be bound by the Stripe Services
                                            Agreement, as the same may be modified by Stripe from time
                                            to time. As a condition of Temp enabling payment processing
                                            services through Stripe, you agree to provide Temp accurate
                                            and complete information about you and your business, and
                                            you authorize Temp to share it and transaction information
                                            related to your use of the payment processing services
                                            provided by Stripe.</p>
                                        <p>Payouts will be made on a weekly basis if the job extends
                                            longer than one week. In the case that the job is shorter
                                            than one week, the payout will be made at the end of the
                                            job. The approval of payouts is held by the Job Provider,
                                            and they will release the payout when worker(s) submits a
                                            completed timesheets (refer to clause 8.2). If the
                                            timesheets are not approved within three days of the job's
                                            completion, the Job Provider agrees the payout will be
                                            calculated based on the worker's hours entered. Note, Temp
                                            does not have authority over payouts.</p>
                                        <p><strong>Workers must ensure:</strong></p>
                                        <p><strong>7.5.1.&nbsp;</strong>That Temp has been supplied the
                                            correct bank account information. Payouts cannot be amended
                                            or revised and it is not the responsibility of Temp to track
                                            down missing funds.</p>
                                        <p><strong>7.5.2. </strong>They pay tax on earnings made through
                                            the Temp platform. As workers operate as independent
                                            contractors, they are responsible for paying their own
                                            taxes.</p>

                                        <!--/Section 8. Timesheets</-->

                                        <p><span style="font-size: 14px;"><strong>8.
                                                    Timesheets</strong></span><br><span
                                                style="font-size: 14px;"><strong>8.1. Timesheet
                                                    entry</strong></span><br>Workers are obligated to
                                            record their hours accurately. This includes their start and
                                            finish times and their break times if applicable. If a
                                            worker fails to record their hours before the job completion
                                            date, then it is at the discretion of the Job provider to
                                            fill it out for them.</p>
                                        <p><span style="font-size: 14px;"><strong>8.2. Timesheet
                                                    approval</strong></span><br>Workers will track their
                                            time worked for the Job Provider and submit a timesheet for
                                            approval via the Temp Platform, unless otherwise agreed in
                                            writing by Temp. The worker(s) must submit their hours, and
                                            the Job Provider must approve their timesheets weekly or at
                                            the completion of the job if shorter than one week.
                                            &nbsp;The Job provider shall verify and approve timesheets
                                            weekly on Fridays unless the job duration is less than 5
                                            days, then it must be approved once the job is completed.
                                            Timesheets must be approved within the Timesheet approval
                                            period of 3 days. If the Job provider does not dispute or
                                            edit the workers hours within the Timesheet Approval Period,
                                            the Job Provider agrees to the payout calculated based on
                                            the worker's hours entered.</p>
                                        <p><span style="font-size: 14px;"><strong>8.3. Timesheet
                                                    discrepancies</strong></span><br>Workers can
                                            complete additional hours with the Job providers approval.
                                            All additional hours should be recorded in the Timesheet on
                                            the Temp platform. If the Job provider agrees to the
                                            additional hours, the Job provider will be charged an
                                            additional fee for the extra hours. In the event the worker
                                            works less than the agreed upon hours, then the Job provider
                                            will be entitled to a refund (clause 7.4).</p>

                                        <!--/Section 9. Promotions</-->

                                        <p><span style="font-size: 14px;"><strong>9.
                                                    Promotions</strong></span><br>Temp is the
                                            &ldquo;<strong>Promoter</strong>&rdquo; responsible for
                                            these promotions.</p>
                                        <p><span style="font-size: 14px;"><strong>9.1 Disqualified
                                                    Participants:</strong></span><br>The following
                                            individuals, referred to as "Disqualified Participants," are
                                            ineligible to participate in these promotions:</p>
                                        <p><strong>9.1.1. </strong>All current Temp employees, all
                                            employees who work for associated marketing or advertising
                                            agencies, and their immediate families.</p>
                                        <p><strong>9.1.2.</strong> All individuals under the age of 18
                                            years if the prize involves air travel or any other element
                                            that is illegal to supply to a person under 18 years of age.
                                        </p>
                                        <p><strong>9.1.3. </strong>All individuals who have won a prize
                                            from a Temp promotion within the last 30 days. If the
                                            previous prize was valued at over $1,000, the winner must
                                            wait 90 days before entering further Temp promotions again.
                                        </p>
                                        <p><span style="font-size: 14px;"><strong>9.2. Promotions &amp;
                                                    Giveaways Entry</strong></span><br><strong>9.2.1.
                                            </strong>These rules, referred to as "the Rules," apply to
                                            all promotions and competitions by Temp, referred to as
                                            "Promotion," whether conducted online or in-person through
                                            any medium, including while completing a job or online
                                            through a connected device. The Rules are subject to change.
                                        </p>
                                        <p><strong>9.2.2.</strong> If a specific Promotion has its own
                                            set of rules or terms, referred to as "Specific Rules,"
                                            those Specific Rules will take precedence in case of any
                                            inconsistencies with the Rules.</p>
                                        <p><strong>9.2.3.</strong> Unless otherwise specified in the
                                            Specific Rules, registration, entry, or voting is limited to
                                            one per person. If multiple registrations, entries, or votes
                                            are allowed, each must be submitted separately.</p>
                                        <p><strong>9.2.4. </strong>By entering the Promotion,
                                            participants acknowledge that they have read and understood
                                            the Rules and the Specific Rules (if applicable) and have
                                            the necessary authority to enter the Promotion (such as
                                            permission from the bill payer or device owner).</p>
                                        <p><strong>9.2.5.</strong> No purchase is necessary to
                                            participate or win, unless stated otherwise in the Specific
                                            Rules.</p>
                                        <p><strong>9.2.6.</strong> The Promotion is open to New Zealand
                                            residents and those who have the right to work or operate as
                                            independent contractors. Disqualified Participants may not
                                            enter the Promotion.</p>
                                        <p><strong>9.2.7. </strong>Temp reserves the right to exclude
                                            any individual from participating in the Promotion for
                                            reasonable grounds. Temp also reserves the right to refuse
                                            to award any prize to a winner if Temp, in its sole
                                            discretion, determines that the winner violated the Rules
                                            (including the Specific Rules), gained an unfair advantage,
                                            or won using fraudulent means.</p>
                                        <p><strong>9.2.8.</strong> Temp reserves the right to refuse to
                                            award any prize to an entrant who Temp decides (in its sole
                                            discretion) has violated the Rules (including the Specific
                                            Rules), gained unfair advantage in participating in the
                                            Promotion or won using fraudulent means.</p>
                                        <p><strong>9.2.9.</strong> By participating, entrants grant Temp
                                            exclusive permission to use their names, characters,
                                            photographs, videos, voices, and likeness in connection with
                                            the Promotion and for future promotional and marketing
                                            purposes. Entrants waive any claims to royalties, rights, or
                                            remuneration for such use.</p>
                                        <p><strong>9.2.10.</strong> All personal information provided by
                                            participants must be valid and up-to-date. Temp may use this
                                            information for the Promotion and future promotional and
                                            marketing purposes in accordance with its Privacy Policy
                                            (available on the website www.temp.org.nz), unless otherwise
                                            directed by the participant at the time of entry.</p>
                                        <p><span style="font-size: 14px;"><strong>9.3. Winning the
                                                    Prize</strong></span></p>
                                        <p><strong>9.3.1.</strong> Only the individual who entered the
                                            Promotion or competition can win the prize, referred to as
                                            the "Winner," unless otherwise specified by Temp in the
                                            Specific Rules.</p>
                                        <p><strong>9.3.2. </strong>The Winner will be determined
                                            according to the Rules or the Specific Rules, or if not
                                            specified, by Temp acting as the judge, referred to as the
                                            "Judge."</p>
                                        <p><strong>9.3.3.</strong> The Judge's determination of the
                                            Winner will be final, and no correspondence will be entered
                                            into.</p>
                                        <p><strong>9.3.4. </strong>The Winner will be notified by either
                                            email, phone (voice or text), mail, or in-person, and must
                                            be available for all required publicity by Temp. If the
                                            Winner cannot be reached after three attempts by phone or if
                                            mail is returned, Temp will select another winner. If the
                                            prize is not collected within two months of being announced,
                                            it will be forfeited. Note that three attempts will be made
                                            to contact the Winner.</p>
                                        <p><strong>9.3.5.</strong> Where the Winner is required to claim
                                            the prize in person, they must provide government issued
                                            photo identification such as a driver's license, or
                                            passport.</p>
                                        <p><strong>9.3.6.</strong> The recipient of the Prize assumes
                                            full responsibility and bears all risks associated with it,
                                            and indemnifies Temp against any claims arising from
                                            accidents, injuries, property damage, or loss of life that
                                            may occur in connection with the Prize. The Winner is also
                                            responsible for all costs, including insurance, taxes, and
                                            other expenses that may be associated with the Prize. If
                                            there are any terms and conditions or limitations associated
                                            with the Prize, the Winner accepts them as a condition of
                                            receiving the Prize.</p>

                                        <!--/Section 10. Exclusions of Liability</-->

                                        <p><span style="font-size: 14px;"><strong>10. Exclusions of
                                                    Liability</strong></span><br>Neither Party shall be
                                            held liable, in contract, tort, under statute or otherwise,
                                            for any consequential or indirect losses sustained by the
                                            other Party, including but not limited to loss of
                                            investment, loss of contract, loss of production, loss of
                                            profits, loss of time, or loss of use.</p>
                                        <p><span style="font-size: 14px;"><strong>10.1 Job
                                                    provider</strong></span></p>
                                        <p><strong>10.1.1&nbsp;</strong>The Job provider acknowledges
                                            that Temp does not hold motor vehicle or any other relevant
                                            insurance for vehicles that are registered or required at
                                            law to be registered and which are not owned by Temp. Temp
                                            shall not be held liable, and the Job provider shall
                                            indemnify Temp and workers on the Temp platform against any
                                            loss (including liability under any indemnity or claims by
                                            third parties) related to a Job Brief, to the extent that
                                            the loss (i) is arising out of or in connection with driving
                                            or operating any motor or commercial vehicle(s) by the
                                            worker(s) in the course of their Work; or (ii) would
                                            ordinarily and commonly be covered by a motor vehicle
                                            insurance policy or any such equivalent or relevant
                                            insurances. If a worker chooses to use their personal Motor
                                            vehicle for a job brief this is at the Workers discretion,
                                            and they will assume full liability.</p>
                                        <p><strong>10.1.2.</strong> The Job provider acknowledges that
                                            the worker will be working under the Job provider's
                                            supervision, control, direction, and instruction at the Job
                                            Site. Due to this reason, the Job provider agrees that Temp
                                            will not be liable to the Job provider for, and the Job
                                            provider will indemnify Temp against, any loss of whatsoever
                                            nature or kind, however caused by one or more of the workers
                                            (including by their negligence) whilst they are working for
                                            the Job provider under a Job Brief.</p>
                                        <p><span style="font-size: 14px;"><strong>10.2
                                                    Workers</strong></span></p>
                                        <p><strong>10.2.1</strong> The worker acknowledges that they act
                                            as an independent contractor and are responsible for paying
                                            their own income tax. It is not the responsibility of Temp
                                            or the Job provider to pay any income tax, ACC or
                                            superannuation benefit. The worker also acknowledges that
                                            Temp will not be responsible for any Tax fines or penalties
                                            incurred due to undeclared income or unpaid tax.</p>
                                        <p><strong>10.2.2.</strong> Temp and the Job provider will not
                                            be accountable for any damage to the worker&rsquo;s personal
                                            property when carrying out a Job. It is the choice of the
                                            worker whether to use a personal motor vehicle or other
                                            personal equipment that may be needed to complete the job.
                                            This should be outlined in the job brief prior to the worker
                                            accepting the job.</p>
                                        <p><strong>10.2.3.</strong> The worker acknowledges that they
                                            will be working under the Job provider's supervision,
                                            control, direction, and instruction at the Work Site. For
                                            this reason, the worker agrees that Temp will not be liable
                                            to the worker for, and the worker will indemnify Temp
                                            against, any loss of whatsoever nature or kind, however
                                            caused by the worker (including by their negligence) whilst
                                            they are working for the Job provider under a Job Brief.</p>

                                        <!--/Section 11. Intellectual Property</-->

                                        <p><span style="font-size: 14px;"><strong>11. Intellectual
                                                    Property</strong></span></p>
                                        <p><strong>11.1</strong> Temp agrees that all Intellectual
                                            property and other information provided by the Job provider
                                            to Temp remains the property of the Job provider.</p>
                                        <p><strong>11.2.</strong> The Job provider acknowledges and
                                            agrees that Temp continues to own all of its own
                                            Intellectual Property existing at the date of this Agreement
                                            or coming into existence during the Term of this Agreement
                                            (including any improvement to such Intellectual Property
                                            developed during the Term of the Agreement). Temp grants the
                                            Job provider a limited, revocable right to access and use
                                            the Temp Platform for the purpose and to the extent as
                                            allowed under this Agreement during the Term.</p>

                                        <!--/Section 12. Notices</-->

                                        <p><span style="font-size: 14px;"><strong>12.
                                                    Notices</strong></span></p>
                                        <p><strong>12.1. </strong>All mention of notices under this
                                            agreement are to be given in writing.</p>
                                        <p><strong>12.2. </strong>Notices must be sent electronically in
                                            the form of email to the contact email set out in this
                                            clause.<strong><br></strong></p>
                                        <p style="font-size: 14px;">(Email):<span
                                                style="color: rgb(0, 176, 116);"> <a
                                                    style="color: rgb(0, 176, 116);"
                                                    href="mailto:support@temp.org.nz">support@temp.org.nz</a>&nbsp;</span>
                                        </p>
                                        <p style="font-size: 14px;">Job provider and Workers: (Address
                                            &amp; Email) as registered through the Temp platform or
                                            updated through written notice.</p>
                                        <p><strong>12.3. </strong>The Job provider and Worker are
                                            responsible for providing Temp with their current email
                                            address. In the event that the last email address provided
                                            to Temp is invalid, or for any reason is not capable of
                                            delivery to Temp, Temp will not recognize that notice has
                                            been given.</p>
                                        <p><strong>12.4.</strong> The Job provider and worker given
                                            notice under this Agreement by Temp will constitute
                                            effective notice by Temp dispatching of an email containing
                                            such notice.</p>

                                        <!--/Section 13.Termination </-->

                                        <p><span style="font-size: 14px;"><strong>13.
                                                    Termination</strong></span><br><span
                                                style="font-size: 14px;"><strong>13.1 Immediate
                                                    termination</strong></span><br>The Job Provider and
                                            the Worker may immediately terminate this Agreement by
                                            written notice to the other if any of the following occurs:
                                        </p>
                                        <ol>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">A
                                                    termination as a result of a Party breaching any
                                                    term of the Agreement and failing to remedy the
                                                    breach within 7 days of being requested to do so, or
                                                    if the breach is irreparable.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">If a Job
                                                    provider becomes Insolvent.&nbsp;</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">If a Job
                                                    provider suspends or ceases, or threatens to suspend
                                                    or cease, a substantial part of its business
                                                    operations.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">
                                                    Termination of this agreement does not constitute a
                                                    cancelation of subscription plans nor an in progress
                                                    job brief.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">If a
                                                    worker suspends or ceases, or threatens to suspend
                                                    or cease, a substantial part of their services.</p>
                                            </li>
                                            <li style="font-size: 14px;" aria-level="1">
                                                <p style="font-size: 14px;" role="presentation">If a Job
                                                    provider or worker engages in fraud, dishonesty, or
                                                    any other serious misconduct, immediate termination
                                                    may be initiated.</p>
                                            </li>
                                        </ol>
                                        <p><strong>13.2. </strong>Additionally, Temp may terminate the
                                            Agreement by providing written notice to the Job provider if
                                            it reasonably believes that the Work Site is or has become
                                            unsafe for any reason.</p>
                                        <p><span style="font-size: 14px;"><strong>13.3. Termination
                                                    without Cause</strong></span><br>Either Party may
                                            terminate this Agreement at any time but will need to
                                            provide reasons and must give 14 days written notice.</p>

                                        <!--/Section 14. Force Majeure</-->

                                        <p><span style="font-size: 14px;"><strong>14. Force
                                                    Majeure</strong></span></p>
                                        <p><strong>14.1.&nbsp;</strong>If a Party is prevented from or
                                            delayed in performing an obligation under this Agreement
                                            (including under any Job Brief, other than an obligation to
                                            pay invoices pursuant to Clause 7.3.2) by a Force Majeure
                                            Event, then the obligation is suspended during, but for no
                                            longer than, the period the Force Majeure Event continues
                                            and such further period as reasonable in the circumstances.
                                            Neither Party shall be liable for any such suspension, delay
                                            or disruption to the performance of any obligations as a
                                            result of such Force Majeure Event.</p>
                                        <p><strong>14.2. </strong>The Party that is prevented from or
                                            delayed in performing the obligation must as soon as
                                            reasonably possible, notify the other Party of its inability
                                            to perform the obligation due to the Force Majeure Event.
                                            This can be done by using the report function either within
                                            the job page for the Worker or in the applicant list for the
                                            Job provider.</p>

                                        <!--/Section 15 Governing Law</-->

                                        <p><span style="font-size: 14px;"><strong>15. Governing
                                                    Law</strong></span></p>
                                        <p>This Agreement will be governed by the laws of New Zealand
                                            and the Parties irrevocably submit to the non-exclusive
                                            jurisdiction of the courts in that jurisdiction.</p>

                                        <!--/Section 16 Right to work</-->

                                        <p><span style="font-size: 14px;"><strong>16. Right to
                                                    work</strong></span></p>
                                        <p>All workers on the temp platform must hold New Zealand
                                            residency or have the necessary Visa or associated
                                            documentation to perform work lawfully. A list of acceptable
                                            visas can be found at www.immigration.govt.nz. All workers
                                            must comply with the Immigration Act 2009 and hold the
                                            applicable documentation to perform work operating as an
                                            independent contractor. Failure to prove or provide such
                                            documentation will result in a ban from the Temp platform.
                                        </p>

                                        <!--/Section 17 Temps Geofence</-->

                                        <p><span style="font-size: 14px;"><strong>17. Temp&rsquo;s
                                                    Geofence</strong></span></p>
                                        <p>Temp uses geofencing technology to ensure that our website is
                                            only accessible to users who are within specific geographic
                                            boundaries. Geofencing is a location-based service that
                                            allows us to define virtual geographic boundaries, enabling
                                            us to restrict access to our website to users who are
                                            physically located within those boundaries.&nbsp;</p>
                                        <p>By using Temp, you consent to our use of geofencing
                                            technology to restrict access to our website based on your
                                            location.&nbsp;</p>
                                        <p>We only collect and use location data to ensure that you are
                                            within the designated geographic boundaries to access our
                                            website. We do not use this data for any other purposes or
                                            share it with any third parties.&nbsp;</p>
                                        <p>If you have any questions or concerns about our use of
                                            geofencing technology, please contact our customer support
                                            team. By using Temp, you agree to our use of geofencing
                                            technology as described in these terms and conditions."</p>

                                        <!--/Section 18 Changes to this agreement</-->

                                        <p><span style="font-size: 14px;"><strong>18. Changes to this
                                                    agreement</strong></span></p>
                                        <p>Temp reserves the right to modify the terms of this Agreement
                                            or its policies relating to the Temp platform at any time,
                                            effective upon the posting of an updated version of this
                                            Agreement on the Temp website.</p>
                                        <p>By continuing to use the Temp platform or receiving Services
                                            from Temp (including continuing with an existing or
                                            outstanding Job), the Job provider and worker confirm they
                                            agree to be bound by this Agreement and any amended terms of
                                            this Agreement.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-2 d-flex justify-content-center position-relative">
                                <div class="position-relative">
                                    <label for="optedwork"
                                        class="customcheckbox-container font-size-3 line-height-reset d-flex pl-8">
                                        Sign up for marketing promotions and offers <span style="
                                color: white;
                            ">qqqqqq</span>
                                        <input type="checkbox" id="optedwork" checked name="optedwork"
                                            formControlName="optedins" (change)="fieldsChange($event)">
                                        <span class="checkmark"></span>
                                    </label>
                                    <label for="terms-check2"
                                        class="customcheckbox-container font-size-3 line-height-reset d-flex pl-8">
                                        I agree and have read the Terms & Conditions above & agree to
                                        pay the subscription if selected
                                        <input type="checkbox" id="terms-check2" name="terms-check2"
                                            formControlName="agreed">
                                        <span class="checkmark"></span>
                                    </label>
                                    <ng-container
                                        *ngIf="(formDirective4.submitted)   && staff5form.get('agreed').invalid">
                                        <small class="text-danger" *ngIf="staff5form.get('agreed').errors">
                                            Field is required
                                        </small>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="form-group mb-0 mt-2 row mx-0 justify-content-center">
                                <div class="col-6 col-md-4 px-0 pr-2">
                                    <button type="button"
                                        class="btn btn-outline-primary btn-small btn-md-medium w-100 rounded-5 text-capitalize signHeight"
                                        (click)="termsAndConditionsBack()">Back</button>
                                </div>
                                <div class="col-6 col-md-4 px-0 pl-2">
                                    <button type="submit"
                                        class="btn btn-primary btn-small btn-md-medium w-100 rounded-5 text-capitalize signHeight">Sign
                                        Up</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>