import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loadStripe } from '@stripe/stripe-js';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class StripeHelper{
    stripePublicKey:string = "";
    public stripe;
    constructor(private httpClient:HttpClient){
        this.getPublicKey();
    }

    getPublicKey(){
        this.httpClient.get(`${environment.API_URL}public/get-publishable-key`).subscribe(async (res:any)=>{
            this.stripePublicKey = res.pkey;
            this.stripe = await loadStripe(res.pkey.masterDataValue);
        })
    }

    getParentElement(clientSecret:string,mount:string){
        const options = {
            clientSecret: clientSecret,
            appearance: {theme: 'stripe'},
          };
        const elements= this.stripe.elements(options);
        const paymentElement = elements.create('payment',{fields: {
                billingDetails: {
                    address: {
                        country: 'never',
                        postalCode: 'never'
                    }
                }
            }
        });
        setTimeout(() => {
            paymentElement.mount(mount);  
        }, 500);
        return elements;
    }
}